import { gql } from '@apollo/client';

export const GET_UI_LABELS = gql`
  query uiLabelsAdmin($filter: UILabelsFilter) {
    uiLabelsAdmin(filter: $filter) {
      count
      uiLabels {
        id
        key
        value
        description
        languageCode
        referenceImage {
          url
          blurhash
        }
        type
      }
    }
  }
`;

export const GET_UI_LABEL = gql`
  query uiLabelAdmin($id: ID!) {
    uiLabelAdmin(where: { id: $id }) {
      id
      key
      type
      value
      description
      languageCode
      language {
        name
        code
      }
      referenceImage {
        id
        url
      }
      type
    }
  }
`;

export const GET_LANGUAGES = gql`
  query languages($filter: LanguageFilter) {
    languages(filter: $filter) {
      count
      languages {
        code
        id
        name
      }
    }
  }
`;
