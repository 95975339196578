import { gql } from '@apollo/client';

export const CORE_TAG_FIELDS = gql`
  fragment CorseFields on Tag {
    id
    name
    id
  }
`;

export const GET_TAGS = gql`
  ${CORE_TAG_FIELDS}
  query tagsAdmin($filter: TagsFilter, $sort: TagsSort) {
    tagsAdmin(filter: $filter, sort: $sort) {
      count
      tags {
        ...CorseFields
      }
    }
  }
`;

export const GET_TAG = gql`
  ${CORE_TAG_FIELDS}
  query tagAdmin($id: ID!) {
    tagAdmin(where: { id: $id }) {
      ...CorseFields
      slug
      metaHeader
      metaFooter
    }
  }
`;
