import { Select, Space, Tag } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  MODULES,
  TAGS_SORT_FIELD
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import Image from '../../../components/Image';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import CustomCard from '../../component/card/Cscard';
import { GET_SOURCES } from './graphql/Queries';

function SourcesCard({
  name,
  createdAt,
  image,
  description,
  articlesCount,
  podcastsCount,
  videosCount,
  showPreview
}) {
  const handleShowPreview = () => {
    showPreview({
      type: ASSET_CATEGORY.IMAGE,
      url: getImageUrl(image?.url) ?? videoPlaceholder
    });
  };

  return (
    <CustomCard
      onClick={handleShowPreview}
      alt={name}
      src={getImageUrl(image?.url) ?? videoPlaceholder}
      blurHash={image?.blurhash}
      title={moment(createdAt).format('ll')}
      heading={name}
      description={description}
    >
      <Space wrap>
        <Tag>{videosCount} Videos</Tag>
        <Tag>{podcastsCount} Podcasts</Tag>
        <Tag>{articlesCount} Articles</Tag>
      </Space>
    </CustomCard>
  );
}

const SOURCES_OPTIONS = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false }
];

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  active
}) => ({
  filter: {
    skip: offset,
    search,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  },
  where: {
    isActive: active
  }
});

const dataSelector = ({ sourcesAdmin }) => ({
  data: sourcesAdmin?.sources ?? [],
  count: sourcesAdmin?.count ?? 0
});

const columns = ({ showPreview }) => [
  {
    title: 'Image',
    dataIndex: 'image',
    width: 150,
    render: (value, { name }) => (
      <Image
        onClick={() => {
          showPreview({
            type: ASSET_CATEGORY.IMAGE,
            url:
              getImageUrl(value?.url, { height: 50, width: 80 }) ??
              videoPlaceholder
          });
        }}
        className="group-img-table pointer"
        blurHash={value?.blurhash}
        src={
          getImageUrl(value?.url, { height: 50, width: 80 }) ?? videoPlaceholder
        }
        alt={name}
      />
    )
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 170,
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Videos',
    dataIndex: 'videosCount',
    width: 100
  },
  {
    title: 'Podcasts',
    dataIndex: 'podcastsCount',
    width: 120
  },
  {
    title: 'Articles',
    dataIndex: 'articlesCount',
    width: 120
  }
];

function Sources() {
  const [active, setActive] = useState(true);
  const filterProps = useFilterBar({
    defaultSortOptions: { options: TAGS_SORT_FIELD }
  });

  const handleActiveChange = (value) => {
    setActive(value);
  };

  const filters = useMemo(
    () => ({
      active,
      ...filterProps?.filters
    }),
    [filterProps?.filters, active]
  );

  return (
    <>
      <PageHeader menu={MODULES?.LABELS} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Sources' }}
        extraFilters={
          <Select
            value={active}
            options={SOURCES_OPTIONS}
            onChange={handleActiveChange}
          />
        }
      />
      <PageList
        filters={filters}
        listMode={filterProps.listMode}
        query={GET_SOURCES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (props, { showPreview }) => (
            <SourcesCard {...props} showPreview={showPreview} />
          )
        }}
        TableProps={{ columns }}
      />
    </>
  );
}

export default Sources;
