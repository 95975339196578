import {
  ExportOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload
} from 'antd';
import { find, findIndex, isArray, isEmpty } from 'lodash';
import { ArrowClockwise } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import * as urlSlug from 'url-slug';
import { v4 as uuidv4 } from 'uuid';
import { useApp } from '../../AppContext';
import {
  CREATE_APP,
  RETRY_APP_DEPLOYMENT,
  UPDATE_APP
} from '../../app/components/sidebar/graphql/Mutation';
import {
  GET_APP_ROOT_DOMAIN,
  GET_WORKSPACES_APP,
  GET_WORKSPACES_APPS,
  GET_WORKSPACES_APPS_TYPES,
  GET_WORKSPACE_APP_SIGNED_URL,
  UPSERT_CUSTOM_DOMAIN
} from '../../app/components/sidebar/graphql/Queries';
import {
  DEFAULT_WEB_COLORS,
  DOMAIN_STATUS,
  LOGO_POSITIONS,
  LOGO_POSITIONS_OPTIONS,
  LOGO_REDIRECTION_DEFAULT_VALUE,
  LOGO_REDIRECTION_TYPE,
  LOGO_SIZES,
  LOGO_SIZES_OPTIONS,
  MAX_LENGTHS,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  REDIRECTION_TYPE,
  REGEX,
  SHOW_MENU,
  SHOW_MENU_OPTIONS,
  STATIC_PAGES,
  STATUS_COLORS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import {
  fileUpload,
  formValidatorRules,
  getBase64,
  status
} from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import PreviewModal from '../../components/PreviewModal';
import ProgressBar from '../../components/ProgressBar';
import useCheckPermission from '../../hooks/useCheckPermission';
import useRedirectUser from '../../hooks/useRedirectUser';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { GET_SLUGS } from '../menus/graphql/Queries';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../videos/components/FormInputs';
import {
  DEFAULT_CONFIGS,
  DEFAULT_CONFIG_KEYS,
  MobileView,
  getConfigData,
  parseConfigData
} from '../workspaces/components';
import DomainRecordsModal from './DomainRecordsModal';

const { TextArea } = Input;

function Configuration({
  form,
  handlePreview,
  uploadButton,
  defaultImageArray,
  defaultImageProgress
}) {
  return (
    <div className="workspace-configs">
      {Object.entries(DEFAULT_CONFIGS)
        ?.filter(
          ([key]) =>
            ![
              DEFAULT_CONFIG_KEYS.COMMUNITY_COLORS,
              DEFAULT_CONFIG_KEYS.COMMUNITY_IMAGE_URLS,
              DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL,
              DEFAULT_CONFIG_KEYS.APP_IMAGE_POSITION,
              DEFAULT_CONFIG_KEYS.APP_IMAGE_SIZE,
              DEFAULT_CONFIG_KEYS.SHOW_MENU,
              DEFAULT_CONFIG_KEYS.LOGO_REDIRECTION
            ].includes(key)
        )
        ?.map(([key, { component: Component, name }]) => (
          <Component
            key={key}
            form={form}
            namePath={['config', name]}
            handlePreview={handlePreview}
            uploadButton={uploadButton}
            defaultImageArray={defaultImageArray}
            defaultImageProgress={defaultImageProgress}
          />
        ))}
    </div>
  );
}

const AddEditApp = ({ history, match: { params } }) => {
  const { state, dispatch } = useApp();
  const workspaceConfig = state?.workspaceConfig || [];
  const workspace = state?.workspace;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imageProgress, setImageProgress] = useState(null);
  const [iconProgress, setIconProgress] = useState(null);
  const [defaultImageProgress, setDefaultImageProgress] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const [domainStatus, setDomainStatus] = useState(null);
  const [openRecordsModal, setOpenRecordsModal] = useState(false);
  const [domainRecords, setDomainRecords] = useState([]);

  const initialValues = useMemo(
    () => ({
      description: '',
      isActive: true,
      name: '',
      typeKey: null,
      slug: '/',
      url: '',
      usingApplicationDomain: false,
      config: parseConfigData(workspaceConfig) || {
        emailVerificationBaseURL: '',
        colors: DEFAULT_WEB_COLORS,
        fonts: [],
        logoSize: LOGO_SIZES.SMALL,
        logoPosition: LOGO_POSITIONS.CENTER,
        showMenu: SHOW_MENU.NO,
        logoRedirection: {
          ...LOGO_REDIRECTION_DEFAULT_VALUE,
          slug: LOGO_REDIRECTION_DEFAULT_VALUE?.url
            ? {
                label: `/${LOGO_REDIRECTION_DEFAULT_VALUE?.url}`,
                value: LOGO_REDIRECTION_DEFAULT_VALUE?.url
              }
            : null
        }
      }
    }),
    []
  );

  const [form] = Form.useForm();
  const redirectionUrl = Form.useWatch(['url'], form);
  const { appId } = params;
  const isEdit = !!appId;
  const { redirectUser } = useRedirectUser();
  const appType = Form.useWatch('typeKey', form);
  const imageArray = Form?.useWatch('image', form);
  const iconArray = Form?.useWatch('icon', form);
  const defaultImageArray = Form?.useWatch(['config', 'defaultImage'], form);
  const customDomain = Form?.useWatch('customDomain', form);
  const usingApplicationDomain = Form?.useWatch('usingApplicationDomain', form);
  const type = Form.useWatch(['config', 'logoRedirection', 'pageType'], form);
  const internalPageType = Form.useWatch(
    ['config', 'logoRedirection', 'internalPageType'],
    form
  );
  const logoRedirectionEnabled = Form.useWatch(
    ['config', 'logoRedirection', 'enabled'],
    form
  );

  const [btnLoading, setBtnLoading] = useState(false);
  const [deploymentStatus, setDeploymentStatus] = useState({
    enabled: false,
    status: null
  });

  const [
    getWorkspaceApp,
    { loading: fetchingDetails, data: workspaceAppData }
  ] = useLazyQuery(GET_WORKSPACES_APP, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const slug = res?.workspaceApp?.slug?.startsWith('/')
        ? res?.workspaceApp?.slug
        : `/${res?.workspaceApp?.slug}`;
      const url = res?.workspaceApp?.url ?? '';
      form?.setFieldsValue({
        ...res?.workspaceApp,
        usingApplicationDomain:
          res?.workspaceApp?.usingApplicationDomain ?? false,
        url,
        slug,
        ...(res?.workspaceApp?.image && {
          image: [{ url: res?.workspaceApp?.image }]
        }),
        ...(res?.workspaceApp?.favicon && {
          icon: [{ url: res?.workspaceApp?.favicon }]
        }),
        config:
          parseConfigData(res?.workspaceApp?.config) || initialValues?.config,
        typeKey: {
          value: res?.workspaceApp?.typeKey
        }
      });
      setDeploymentStatus({
        enabled: res?.workspaceApp?.deployment?.enabled,
        status: res?.workspaceApp?.deployment?.status
      });
      setDomainStatus(res?.workspaceApp?.domainStatus);
      setDomainRecords(res?.workspaceApp?.domainVerificationRecords);
    },
    onError: () => {}
  });

  useEffect(() => {
    form?.resetFields();
    setDeploymentStatus({ enabled: true, status: null });
    setDomainStatus(null);
  }, [appId, form]);

  useEffect(() => {
    if (isEdit) {
      getWorkspaceApp({
        variables: {
          where: { id: appId }
        }
      });
    } else {
      setDomainStatus(null);
    }
  }, [isEdit, params]);

  const [addUpdateApp] = useMutation(isEdit ? UPDATE_APP : CREATE_APP, {
    refetchQueries: [GET_WORKSPACES_APPS],
    onError: () => {
      setBtnLoading(false);
    }
  });

  const { data: domainData } = useQuery(GET_APP_ROOT_DOMAIN, {
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [getSignedUrl] = useLazyQuery(GET_WORKSPACE_APP_SIGNED_URL, {
    onError: () => {
      setBtnLoading(false);
    }
  });

  const [retryAppDeployment, { loading }] = useMutation(RETRY_APP_DEPLOYMENT, {
    refetchQueries: [
      {
        query: GET_WORKSPACES_APP,
        variables: {
          where: { id: appId }
        }
      }
    ],
    onError() {}
  });

  const [upsertAppCustomDomain, { loading: domainLoading }] = useMutation(
    UPSERT_CUSTOM_DOMAIN,
    {
      refetchQueries: [
        {
          query: GET_WORKSPACES_APP,
          variables: {
            where: { id: appId }
          }
        }
      ],
      onError() {}
    }
  );

  const handleSubmit = async (data) => {
    setBtnLoading(true);
    let contentType = null;
    const payload = {
      ...data,
      typeKey: data?.typeKey?.value,
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      config: getConfigData(data?.config, [
        DEFAULT_CONFIG_KEYS.COLORS,
        DEFAULT_CONFIG_KEYS.FONTS,
        DEFAULT_CONFIG_KEYS.APP_IMAGE_POSITION,
        DEFAULT_CONFIG_KEYS.APP_IMAGE_SIZE,
        DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE,
        DEFAULT_CONFIG_KEYS.SHOW_MENU,
        DEFAULT_CONFIG_KEYS.LOGO_REDIRECTION
      ])
    };
    const currentImage = data?.image?.[0];
    if (data?.image?.length > 0 && !currentImage?.url) {
      const uuid = uuidv4();
      const imageObj = currentImage;
      const imageFile = imageObj?.originFileObj;
      const fileName = imageObj?.name;
      contentType = imageObj?.type;
      const { data: signUrlData, error } = await getSignedUrl({
        variables: {
          data: { contentType, fileName, uuid, workspaceId: workspace?.id }
        }
      });
      if (error) return;
      const signedUrlData = signUrlData?.getWorkspaceAppImageUploadSignedUrl;
      await fileUpload(signedUrlData?.signedUrl, imageFile, setImageProgress);
      payload.imageKey = signedUrlData?.key;
      delete payload?.image;
    } else {
      payload.imageKey = null;
      delete payload?.image;
    }
    const currentIcon = data?.icon?.[0];
    if (data?.icon?.length > 0 && !currentIcon?.url) {
      const uuid = uuidv4();
      const iconObj = currentIcon;
      const iconFile = iconObj?.originFileObj;
      const fileName = iconObj?.name;
      contentType = iconObj?.type;
      const { data: signUrlData, error } = await getSignedUrl({
        variables: {
          data: { contentType, fileName, uuid, workspaceId: workspace?.id }
        }
      });
      if (error) return;
      const signedUrlData = signUrlData?.getWorkspaceAppImageUploadSignedUrl;
      await fileUpload(signedUrlData?.signedUrl, iconFile, setIconProgress);
      payload.faviconKey = signedUrlData?.key;
      delete payload?.icon;
    } else {
      payload.faviconKey = null;
      delete payload?.icon;
    }

    const currentDefaultImage = find(
      payload?.config,
      (item) => item.key === DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE
    )?.value?.[0];
    if (currentDefaultImage) {
      const defaultImageIndex = findIndex(
        payload.config,
        (item) => item?.key === DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE
      );
      if (!currentDefaultImage?.url) {
        const uuid = uuidv4();
        const imageObj = currentDefaultImage;
        const imageFile = imageObj?.originFileObj;
        const fileName = imageObj?.name;
        contentType = imageObj?.type;
        const { data: signUrlData, error } = await getSignedUrl({
          variables: {
            data: { contentType, fileName, uuid, workspaceId: workspace?.id }
          }
        });
        if (error) return;
        const signedUrlData = signUrlData?.getWorkspaceAppImageUploadSignedUrl;
        await fileUpload(
          signedUrlData?.signedUrl,
          imageFile,
          setDefaultImageProgress
        );
        if (defaultImageIndex !== -1) {
          payload.config[defaultImageIndex].value = signedUrlData?.key;
        }
      } else if (defaultImageIndex !== -1) {
        payload.config[defaultImageIndex].value = null;
      }
    }
    if (currentImage?.url) {
      const editValue = workspaceAppData?.workspaceApp;
      payload.imageKey = editValue?.imageKey;
      delete payload?.image;
    }
    if (currentIcon?.url) {
      const editValue = workspaceAppData?.workspaceApp;
      payload.faviconKey = editValue?.faviconKey;
      delete payload?.favicon;
    }
    if (isEdit) {
      delete payload?.typeKey;
    }
    delete payload?.customDomain;
    delete payload?.usingApplicationDomain;
    const response = await addUpdateApp({
      variables: {
        data: payload,
        ...(isEdit && {
          where: { id: appId }
        })
      }
    });
    if (!isEdit && response?.data?.createWorkspaceApp?.workspaceApp) {
      const { workspaceApp } = response?.data?.createWorkspaceApp || {};

      dispatch({
        type: 'SET_APP',
        data: {
          label: workspaceApp?.name,
          value: workspaceApp?.uuid,
          id: workspaceApp?.id
        }
      });

      const getRoute = redirectUser();
      history?.push(getRoute);
    } else if (response?.data) {
      const getRoute = redirectUser();
      history?.push(getRoute);
      setBtnLoading(false);
      setIconProgress(null);
      setImageProgress(null);
    }
  };

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleCancel = () => {
    const getRoute = redirectUser();
    history?.push(getRoute);
  };

  const uploadButton = (
    <div>
      {btnLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewImage('');
    setPreviewOpen(false);
  };

  const handleNameChange = (e) => {
    form?.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  return (
    <div>
      <PreviewModal
        show={previewOpen}
        url={previewImage}
        onClose={handlePreviewClose}
      />
      {openRecordsModal && (
        <DomainRecordsModal
          openModal={openRecordsModal}
          setOpenModal={setOpenRecordsModal}
          data={domainRecords}
          appId={appId}
          refetch={getWorkspaceApp}
        />
      )}
      <PageHeader noNavigation />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Row>
            <Col sm={12}>
              <Form
                className="add-edit-form"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={initialValues}
                disabled={btnLoading || fetchingDetails}
              >
                <Form.Item
                  label="App Name"
                  name="name"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter app name!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS?.NAME)
                  ]}
                >
                  <Input
                    placeholder="Enter app name"
                    onChange={handleNameChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <TextArea
                    rows={2}
                    placeholder="Enter description"
                    disabled={btnLoading}
                  />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="typeKey"
                  rules={[
                    {
                      required: true,
                      message: 'Please select type!'
                    }
                  ]}
                  hidden={isEdit}
                >
                  <Select
                    popupMatchSelectWidth={false}
                    variablesSelector={() => ({})}
                    placeholder="Select Type"
                    query={GET_WORKSPACES_APPS_TYPES}
                    dataSelector={(data) =>
                      data?.workspaceAppTypes?.map(({ key, name }) => ({
                        label: name,
                        value: key
                      })) || []
                    }
                    keys={{
                      data: 'workspaceAppTypes',
                      records: 'workspaceAppTypes',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                {((!isEdit && appType && appType?.value !== 'WEB_APP') ||
                  isEdit) && (
                  <>
                    <div className="d-flex justify-between url-div">
                      <div>
                        <Button
                          onClick={() => {
                            if (redirectionUrl) {
                              // eslint-disable-next-line no-undef
                              window.open(redirectionUrl);
                            }
                          }}
                          className="text-btn mr-8 b-0 large font-18"
                          size="middle"
                          disabled={false}
                        >
                          Url <ExportOutlined />
                        </Button>
                        <Tooltip
                          title={
                            appType?.value === 'WEB_APP'
                              ? 'Used in email & sharing url'
                              : 'Used for Deep Linking in Email & Sharing url'
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      {deploymentStatus?.status && (
                        <div className="d-flex align-center">
                          <Tag
                            className="m-0 custom-tag mr-6"
                            color={STATUS_COLORS[deploymentStatus?.status]}
                          >
                            {status[deploymentStatus?.status]}
                          </Tag>
                          {[
                            DOMAIN_STATUS.ERRORED,
                            DOMAIN_STATUS.FAILED,
                            DOMAIN_STATUS.SUCCESS
                          ]?.includes(deploymentStatus?.status) && (
                            <Tooltip title="Re-deploy" className="pointer">
                              {loading ? (
                                <LoadingOutlined />
                              ) : (
                                <ArrowClockwise
                                  size={16}
                                  onClick={() => {
                                    retryAppDeployment({
                                      variables: {
                                        where: {
                                          id: appId
                                        },
                                        ...(deploymentStatus?.status ===
                                          DOMAIN_STATUS.SUCCESS && {
                                          data: {
                                            forceRetry: true
                                          }
                                        })
                                      }
                                    });
                                  }}
                                />
                              )}
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                    <Form.Item
                      name="url"
                      extra={
                        deploymentStatus?.status === DOMAIN_STATUS.PROCESSING
                          ? 'It will take up to 5-10 mins to reflect.'
                          : ''
                      }
                    >
                      <Input
                        placeholder="Url"
                        disabled={
                          isEdit &&
                          appType?.value === 'WEB_APP' &&
                          deploymentStatus?.enabled
                        }
                      />
                    </Form.Item>
                  </>
                )}
                {appType?.value === 'WEB_APP' &&
                  deploymentStatus?.enabled &&
                  deploymentStatus?.status === DOMAIN_STATUS.SUCCESS && (
                    <>
                      <Form.Item
                        name="usingApplicationDomain"
                        valuePropName="checked"
                      >
                        <Switch
                          label="Enable custom domain"
                          disabled={[
                            DOMAIN_STATUS.VERIFICATION_PENDING,
                            DOMAIN_STATUS.SUCCESS
                          ]?.includes(domainStatus)}
                        />
                      </Form.Item>
                      <div className="d-flex align-center justify-between url-div">
                        <Form.Item
                          label="Custom Domain"
                          dependencies={['usingApplicationDomain']}
                          rules={[
                            !usingApplicationDomain
                              ? formValidatorRules?.domain
                              : formValidatorRules?.customDomain
                          ]}
                          name="customDomain"
                          extra={
                            !usingApplicationDomain
                              ? `It will take up to 1 Hour to reflect.\n${
                                  domainData?.getApplicationRootDomain
                                    ? `(${
                                        form.getFieldValue('customDomain') ?? ''
                                      }.${
                                        domainData?.getApplicationRootDomain
                                      })`
                                    : ''
                                }`
                              : ''
                          }
                        >
                          <Input
                            placeholder="Custom Domain"
                            disabled={[
                              DOMAIN_STATUS.VERIFICATION_PENDING,
                              DOMAIN_STATUS.SUCCESS
                            ]?.includes(domainStatus)}
                          />
                        </Form.Item>
                        <Button
                          loading={domainLoading}
                          onClick={() => {
                            if (!isEmpty(customDomain)) {
                              upsertAppCustomDomain({
                                variables: {
                                  where: {
                                    id: appId
                                  },
                                  data: {
                                    customDomain,
                                    usingApplicationDomain
                                  }
                                }
                              });
                            }
                          }}
                          disabled={
                            !customDomain ||
                            [
                              DOMAIN_STATUS.VERIFICATION_PENDING,
                              DOMAIN_STATUS.SUCCESS
                            ]?.includes(domainStatus)
                          }
                          className={`${
                            !usingApplicationDomain ? 'mb-24' : ''
                          }`}
                        >
                          Update Domain
                        </Button>
                      </div>
                      {domainStatus === DOMAIN_STATUS.VERIFICATION_PENDING && (
                        <Tooltip title="Custom Domain DNS records">
                          <Button
                            className="mb-24"
                            onClick={() => {
                              setOpenRecordsModal(true);
                            }}
                          >
                            View all records
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}
                <Form.Item name="isActive" valuePropName="checked">
                  <Switch label="Active" />
                </Form.Item>
                <Form.Item
                  name="image"
                  label={
                    <div className="d-flex">
                      <p className="mr-6">Logo</p>
                      <Tooltip title="Used as Logo for website and meta image default">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                  }
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  rules={[{ required: true, message: 'Please select logo!' }]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    maxCount={1}
                    accept=".jpg,.jpeg,.png"
                    onPreview={handlePreview}
                  >
                    {imageArray?.length > 0 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                {imageProgress && imageProgress >= 0 && (
                  <Form.Item>
                    <ProgressBar progress={imageProgress} />
                  </Form.Item>
                )}
                <Form.Item
                  label="Logo Size"
                  name={['config', 'logoSize']}
                  rules={[
                    { required: true, message: 'Please select logo size' }
                  ]}
                >
                  <AntdSelect
                    placeholder="Select size"
                    options={LOGO_SIZES_OPTIONS}
                  />
                </Form.Item>
                <Form.Item
                  label="Logo Position"
                  name={['config', 'logoPosition']}
                  rules={[
                    { required: true, message: 'Please select logo position' }
                  ]}
                >
                  <AntdSelect
                    placeholder="Select position"
                    options={LOGO_POSITIONS_OPTIONS}
                  />
                </Form.Item>
                <fieldset className="mb-16">
                  <legend className="role-legend text-label">
                    Logo Redirection
                  </legend>
                  <Form.Item
                    name={['config', 'logoRedirection', 'enabled']}
                    valuePropName="checked"
                  >
                    <Switch label="Active" />
                  </Form.Item>
                  <Form.Item
                    label="Type"
                    name={['config', 'logoRedirection', 'pageType']}
                    rules={[
                      {
                        required: logoRedirectionEnabled,
                        message: 'Please select page type!'
                      }
                    ]}
                  >
                    <AntdSelect
                      disabled={!logoRedirectionEnabled || false}
                      options={LOGO_REDIRECTION_TYPE}
                      placeholder="Select page type"
                      onChange={() => {
                        form.resetFields([
                          ['config', 'logoRedirection', 'internalPageType'],
                          ['config', 'logoRedirection', 'slug'],
                          ['config', 'logoRedirection', 'url']
                        ]);
                      }}
                    />
                  </Form.Item>
                  {type === REDIRECTION_TYPE.INTERNAL && (
                    <>
                      <Form.Item
                        label="Page Type"
                        name={['config', 'logoRedirection', 'internalPageType']}
                        rules={[
                          {
                            required:
                              logoRedirectionEnabled &&
                              type === REDIRECTION_TYPE.INTERNAL,
                            message: 'Please select page type!'
                          }
                        ]}
                      >
                        <AntdSelect
                          disabled={!logoRedirectionEnabled || false}
                          options={PAGE_TYPES_OPTIONS}
                          placeholder="Select page type"
                          onChange={() => {
                            form.resetFields([
                              ['config', 'logoRedirection', 'slug'],
                              ['config', 'logoRedirection', 'url']
                            ]);
                          }}
                        />
                      </Form.Item>
                      {internalPageType && (
                        <>
                          {internalPageType === PAGE_TYPES.STATIC ? (
                            <Form.Item
                              label="Page"
                              name={['config', 'logoRedirection', 'slug']}
                              rules={[
                                {
                                  required:
                                    logoRedirectionEnabled &&
                                    type === REDIRECTION_TYPE.INTERNAL,
                                  message: 'Please select page!'
                                }
                              ]}
                            >
                              <AntdSelect
                                disabled={!logoRedirectionEnabled || false}
                                options={STATIC_PAGES}
                                onChange={(value) => {
                                  form.setFieldValue(
                                    ['config', 'logoRedirection', 'url'],
                                    `/${value}`
                                  );
                                  form.validateFields([
                                    'config',
                                    'logoRedirection',
                                    'url'
                                  ]);
                                }}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              label="Page"
                              name={['config', 'logoRedirection', 'slug']}
                              rules={[
                                {
                                  required:
                                    logoRedirectionEnabled &&
                                    type === REDIRECTION_TYPE.INTERNAL,
                                  message: 'Please select page!'
                                }
                              ]}
                            >
                              <Select
                                disabled={!logoRedirectionEnabled || false}
                                placeholder="Select"
                                query={GET_SLUGS}
                                variablesSelector={(filters) => ({
                                  filter: {
                                    ...filters,
                                    pageType: internalPageType
                                  }
                                })}
                                dataSelector={(data) => {
                                  return (
                                    data?.slugs?.slugs?.map(({ slug }) => ({
                                      label: `/${slug}`,
                                      value: slug
                                    })) ?? []
                                  );
                                }}
                                keys={{
                                  data: 'slugs',
                                  records: 'slugs',
                                  count: 'count'
                                }}
                                onChange={({ value }) => {
                                  form.setFieldValue(
                                    ['config', 'logoRedirection', 'url'],
                                    `/${value}`
                                  );
                                  form.validateFields([
                                    'config',
                                    'logoRedirection',
                                    'url'
                                  ]);
                                }}
                              />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Form.Item
                    label="URL"
                    name={['config', 'logoRedirection', 'url']}
                    rules={[
                      {
                        required: logoRedirectionEnabled,
                        message: 'Please enter URL!'
                      },
                      {
                        async validator(_, value) {
                          if (
                            type === REDIRECTION_TYPE.EXTERNAL &&
                            value &&
                            !REGEX?.WEB_URL?.test(value)
                          ) {
                            return Promise?.reject(
                              new Error('Should be a valid URL')
                            );
                          }
                          return Promise?.resolve();
                        }
                      }
                    ]}
                  >
                    <Input
                      placeholder="Enter url"
                      readOnly={type === REDIRECTION_TYPE.INTERNAL}
                      disabled={
                        type === REDIRECTION_TYPE.INTERNAL ||
                        !logoRedirectionEnabled ||
                        false
                      }
                    />
                  </Form.Item>
                </fieldset>
                <Form.Item
                  label="Show Menu"
                  name={['config', 'showMenu']}
                  rules={[
                    { required: true, message: 'Please select yes or no' }
                  ]}
                >
                  <AntdSelect
                    placeholder="Select yes or no"
                    options={SHOW_MENU_OPTIONS}
                  />
                </Form.Item>
                <Form.Item
                  name="icon"
                  label={
                    <div className="d-flex">
                      <p className="mr-6">Favicon</p>
                      <Tooltip title="Used as Favicon for website">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                  }
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  rules={[
                    { required: true, message: 'Please select favicon icon!' }
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    maxCount={1}
                    accept=".ico,.png"
                    onPreview={handlePreview}
                  >
                    {iconArray?.length > 0 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                {iconProgress && iconProgress >= 0 && (
                  <Form.Item>
                    <ProgressBar progress={iconProgress} />
                  </Form.Item>
                )}
                <Divider orientation="left">Configurations</Divider>
                <Configuration
                  form={form}
                  handlePreview={handlePreview}
                  uploadButton={uploadButton}
                  defaultImageArray={defaultImageArray}
                  defaultImageProgress={defaultImageProgress}
                />
                <div className="d-flex button-section mb-8">
                  <Space>
                    {isAddEditAllowed && (
                      <Button
                        disabled={fetchingDetails}
                        loading={btnLoading}
                        type="text"
                        htmlType="submit"
                        className="text-btn mr-8"
                        size="middle"
                      >
                        Save
                      </Button>
                    )}

                    <Button
                      disabled={fetchingDetails || btnLoading}
                      type="text"
                      className="text-btn2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </Form>
            </Col>
            <Col sm={12}>
              <MobileView form={form} namePath={['config', 'colors']} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddEditApp;
