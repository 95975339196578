import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Error404 from '../Error404';
import {
  ROLE_KEYS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../common/constants';
import AccessControl from '../components/AccessControl';
import LoaderComponent from '../components/LoaderComponent';
import NoAccess from '../components/NoAccess';
import useRedirectUser from '../hooks/useRedirectUser';
import AdminUsersWrapper from '../modules/admin-users';
import AppsWrapper from '../modules/apps';
import ArticlesWrapper from '../modules/articles';
import AudioWrapper from '../modules/assets/audio';
import DocumentWrapper from '../modules/assets/document';
import IconsWrapper from '../modules/assets/icons';
import ImagesWrapper from '../modules/assets/images';
import TextWrapper from '../modules/assets/text';
import VideosWrapper from '../modules/assets/videos';
import CommunitiesWrapper from '../modules/communities';
import Components from '../modules/component';
import ContactInquiryWrapper from '../modules/contact-inquiry';
import DashboardWrapper from '../modules/dashboard';
import DonationWrapper from '../modules/donations';
import ExportJobsWrapper from '../modules/export-jobs';
import FormWrapper from '../modules/form';
import CollectionsWrapper from '../modules/labels/collections';
import ContributorWrapper from '../modules/labels/contributor';
import SourcesWrapper from '../modules/labels/sources';
import TagsWrapper from '../modules/labels/tags';
import TopicsWrapper from '../modules/labels/topics';
import MenusWrapper from '../modules/menus';
import PostsWrapper from '../modules/moderation/posts';
import NewsLetterWrapper from '../modules/news-letters';
import PagesWrapper from '../modules/pages';
import PlatformsWrapper from '../modules/platforms';
import PodcastModuleWrapper from '../modules/podcast';
import ProfileWrapper from '../modules/profile';
import RolesWrapper from '../modules/roles';
import UILabelsWrapper from '../modules/ui-labels';
import UsersWrapper from '../modules/users';
import VideosModuleWrapper from '../modules/videos';
import WorkspacesWrapper from '../modules/workspaces';
import WorkspaceUsersWrapper from '../modules/worskspace-users';

const ContentRoutes = () => {
  const {
    state: {
      userPermissions,
      app,
      workspace,
      workSpaceAppsLoading,
      workspaceLoading,
      currentUser: { roles = [] } = null
    }
  } = useContext(AppContext);
  const [getRoute, setGetRoute] = useState(null);
  const { redirectUser } = useRedirectUser();

  useEffect(() => {
    if (!isEmpty(userPermissions) && workspace) {
      setGetRoute(redirectUser(userPermissions));
    }
  }, [userPermissions]);

  if (workspaceLoading || workSpaceAppsLoading || !getRoute)
    return <LoaderComponent />;

  return (
    <>
      <Switch>
        <Route path={ROUTES?.COMPONENTS} render={() => <Components />} />
        <Route
          path={ROUTES?.VIDEOS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <VideosWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.ICONS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <IconsWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.MENUS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                },
                workspace?.id &&
                  app?.id && {
                    moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                    allowedPermissions: [
                      WORKSPACE_ROLE_LEVEL.VIEW,
                      WORKSPACE_ROLE_LEVEL.EDIT,
                      WORKSPACE_ROLE_LEVEL.DELETE
                    ]
                  }
              ]}
            >
              <MenusWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.DOCUMENT}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <DocumentWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.AUDIO}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <AudioWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.TEXT}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <TextWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.CONTRIBUTOR}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <ContributorWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.TOPICS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <TopicsWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.TAGS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <TagsWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.COLLECTIONS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <CollectionsWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.IMAGES}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <ImagesWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.VIDEOS_MODULE}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <VideosModuleWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PAGES_MODULE}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                },
                workspace?.id &&
                  app?.id && {
                    moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
                    allowedPermissions: [
                      WORKSPACE_ROLE_LEVEL.VIEW,
                      WORKSPACE_ROLE_LEVEL.EDIT,
                      WORKSPACE_ROLE_LEVEL.DELETE
                    ]
                  }
              ]}
            >
              <PagesWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.UI_LABELS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                },
                workspace?.id &&
                  app?.id && {
                    moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                    allowedPermissions: [
                      WORKSPACE_ROLE_LEVEL.VIEW,
                      WORKSPACE_ROLE_LEVEL.EDIT,
                      WORKSPACE_ROLE_LEVEL.DELETE
                    ]
                  }
              ]}
            >
              <UILabelsWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.USERS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <UsersWrapper {...props} />
            </AccessControl>
          )}
        />
        {[ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(roles?.[0]) && (
          <Route path={ROUTES?.ADMIN_USERS} component={AdminUsersWrapper} />
        )}
        <Route
          path={ROUTES?.ROLES}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <RolesWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.DONATIONS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <DonationWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.LEAD_GENS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <NewsLetterWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.POSTS}
          render={(props) => (
            // TODO: Handle permissions once added from BE
            // <AccessControl
            //   modulePermissions={[
            //     {
            //       moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
            //       allowedPermissions: [
            //         WORKSPACE_ROLE_LEVEL.VIEW,
            //         WORKSPACE_ROLE_LEVEL.EDIT,
            //         WORKSPACE_ROLE_LEVEL.DELETE
            //       ]
            //     }
            //   ]}
            // >
            <PostsWrapper {...props} />
            // </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.CONTACT_INQUIRY}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <ContactInquiryWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.EXPORT_JOBS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <ExportJobsWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route path={ROUTES?.PROFILE} component={ProfileWrapper} />
        <Route path={ROUTES?.DASHBOARD} component={DashboardWrapper} />
        <Route
          path={ROUTES?.PLATFORMS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <PlatformsWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PODCAST_MODULE}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <PodcastModuleWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.ARTICLES_MODULE}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <ArticlesWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.WORKSPACE_USERS}
          component={WorkspaceUsersWrapper}
        />
        <Route
          path={ROUTES.FORMS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <FormWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.SOURCES}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <SourcesWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.COMMUNITY_MODULE}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <CommunitiesWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.APPS}
          render={(props) => (
            <AccessControl
              modulePermissions={[
                {
                  moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
                  allowedPermissions: [
                    WORKSPACE_ROLE_LEVEL.VIEW,
                    WORKSPACE_ROLE_LEVEL.EDIT,
                    WORKSPACE_ROLE_LEVEL.DELETE
                  ]
                }
              ]}
            >
              <AppsWrapper {...props} />
            </AccessControl>
          )}
        />
        {[ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(roles?.[0]) && (
          <Route
            path={ROUTES?.WORKSPACES}
            render={(props) => <WorkspacesWrapper {...props} />}
          />
        )}

        <Route path={ROUTES.NO_ACCESS} exact component={NoAccess} />
        <Route
          exact
          path={ROUTES?.MAIN}
          render={() => <Redirect to={getRoute} />}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
