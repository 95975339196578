import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import AddEditTags from './AddEditTags';
import Tags from './Tags';

const TagsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.TAGS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Tags {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.TAGS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditTags {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.TAGS}/:tagId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditTags {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.TAGS}/:tagId`}
        render={() => <Redirect to={ROUTES?.TAGS} />}
      />
    </Switch>
  );
};

export default TagsWrapper;
