import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditPlatform from './AddEditPlatform';
import Platforms from './Platforms';

const PlatformsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.PLATFORMS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Platforms {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PLATFORMS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditPlatform {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PLATFORMS}/:platformId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditPlatform {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PLATFORMS}/:platformId`}
        render={() => <Redirect to={ROUTES?.PLATFORMS} />}
      />
    </Switch>
  );
};

export default PlatformsWrapper;
