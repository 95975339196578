import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import { omit } from 'lodash';
import React, { useState } from 'react';
import * as urlSlug from 'url-slug';
import { GET_WORKSPACES_APPS } from '../../../app/components/sidebar/graphql/Queries';
import {
  LOGO_REDIRECTION_TYPE,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  REDIRECTION_TYPE,
  REGEX,
  ROUTES,
  STATIC_PAGES
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import history from '../../../historyData';
import { SlugInput } from '../../labels/topics/components/FormInputs';
import { GET_SLUGS } from '../../menus/graphql/Queries';
import { Select } from '../../videos/components/FormInputs';
import { CREATE_PAGE } from '../graphql/Mutations';
import { Permissions, Switch } from './pageModules/moduleForms/FormInputs';

const { required } = formValidatorRules;

const OPTIONS = PAGE_TYPES_OPTIONS.filter(
  ({ value }) => value !== PAGE_TYPES.STATIC
);

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const initialValues = {
  apps: [],
  description: '',
  metaFooter: '',
  metaHeader: '',
  permissions: [],
  slug: '',
  title: '',
  type: PAGE_TYPES.CUSTOM,
  logoRedirection: {
    enabled: true,
    pageType: REDIRECTION_TYPE.INTERNAL,
    internalPageType: PAGE_TYPES.CUSTOM,
    url: '//',
    slug: {
      label: `//`,
      value: '/'
    }
  }
};

const AddPage = () => {
  const [form] = Form.useForm();
  const type = Form.useWatch('type', form) ?? PAGE_TYPES.CUSTOM;
  const pageType = Form.useWatch(['logoRedirection', 'pageType'], form);
  const internalPageType = Form.useWatch(
    ['logoRedirection', 'internalPageType'],
    form
  );
  const logoRedirectionEnabled = Form.useWatch(
    ['logoRedirection', 'enabled'],
    form
  );

  const [buttonLoading, setButtonLoading] = useState(false);

  const [createPageMutate] = useMutation(CREATE_PAGE, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.PAGES_MODULE);
  };

  const onFinish = async ({ apps, page: _page, ...values }) => {
    setButtonLoading(true);
    try {
      const formData = {
        ...values,
        permissions: values?.permissions?.map(({ value }) => value),
        slug: values.slug.startsWith('/')
          ? values.slug.substring(1)
          : values.slug,
        appIds: apps?.map(({ value }) => value),
        logoRedirection: {
          ...omit(values?.logoRedirection, ['slug']),
          ...(values?.logoRedirection?.pageType ===
            REDIRECTION_TYPE.INTERNAL && {
            url: values?.logoRedirection?.url?.startsWith('/')
              ? values?.logoRedirection?.url?.substring(1)
              : values?.logoRedirection?.url
          })
        }
      };
      const response = await createPageMutate({
        variables: {
          data: {
            ...formData
          }
        }
      });
      if (response?.data?.createPage) {
        setButtonLoading(false);

        history?.replace(
          `${ROUTES?.PAGES_MODULE}/${response?.data?.createPage?.page?.id}/edit`
        );
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const handleNameChange = (e) => {
    if (type === PAGE_TYPES.CUSTOM) {
      form?.setFieldValue('slug', `/${urlSlug?.convert(e?.target?.value)}`);
    }
  };

  return (
    <>
      <PageHeader menu={MODULES?.UI_CONFIGURATION} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            name="create-page"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input
                placeholder="Enter title"
                disabled={buttonLoading}
                onChange={handleNameChange}
              />
            </Form.Item>

            <Form.Item name="type" label="Type">
              <AntdSelect
                options={OPTIONS}
                onChange={() => {
                  form.resetFields(['slug', 'page']);
                }}
              />
            </Form.Item>

            {type === PAGE_TYPES.CUSTOM ? (
              <>
                <Form.Item label="Apps" name="apps">
                  <Select
                    multiple
                    placeholder="Select apps"
                    query={GET_WORKSPACES_APPS}
                    variablesSelector={variablesSelector}
                    dataSelector={(data) => {
                      return (
                        data?.workspaceApps?.workspaceApps?.map(
                          ({ id, name }) => ({
                            label: name,
                            value: id
                          })
                        ) ?? []
                      );
                    }}
                    keys={{
                      data: 'workspaceApps',
                      records: 'workspaceApps',
                      count: 'count'
                    }}
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item label="Page" name="page">
                <Select
                  placeholder="Select"
                  query={GET_SLUGS}
                  variablesSelector={(filter) => ({
                    filter: {
                      ...filter,
                      pageType: type
                    }
                  })}
                  dataSelector={(data) => {
                    return (
                      data?.slugs?.slugs?.map(({ slug }) => ({
                        label: `/${slug}`,
                        value: slug
                      })) ?? []
                    );
                  }}
                  keys={{
                    data: 'slugs',
                    records: 'slugs',
                    count: 'count'
                  }}
                  onChange={({ value }) => {
                    form.setFieldValue(['slug'], `/${value}`);
                  }}
                />
              </Form.Item>
            )}

            <Form.Item
              name="slug"
              label="Page Slug"
              rules={[
                { required, message: 'Please enter slug!' },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>

            <Form.Item
              name="description"
              label="Page Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input placeholder="Enter description" disabled={buttonLoading} />
            </Form.Item>

            <fieldset className="mb-16 fieldset-width">
              <legend className="role-legend text-label">
                Logo Redirection
              </legend>
              <Form.Item
                name={['logoRedirection', 'enabled']}
                valuePropName="checked"
              >
                <Switch label="Active" />
              </Form.Item>
              <Form.Item
                label="Type"
                name={['logoRedirection', 'pageType']}
                rules={[
                  {
                    required: logoRedirectionEnabled,
                    message: 'Please select page type!'
                  }
                ]}
              >
                <AntdSelect
                  disabled={!logoRedirectionEnabled || false}
                  options={LOGO_REDIRECTION_TYPE}
                  placeholder="Select page type"
                  onChange={() => {
                    form.resetFields([
                      ['logoRedirection', 'internalPageType'],
                      ['logoRedirection', 'slug'],
                      ['logoRedirection', 'url']
                    ]);
                  }}
                />
              </Form.Item>
              {pageType === REDIRECTION_TYPE.INTERNAL && (
                <>
                  <Form.Item
                    label="Page Type"
                    name={['logoRedirection', 'internalPageType']}
                    rules={[
                      {
                        required:
                          logoRedirectionEnabled &&
                          pageType === REDIRECTION_TYPE.INTERNAL,
                        message: 'Please select page type!'
                      }
                    ]}
                  >
                    <AntdSelect
                      disabled={!logoRedirectionEnabled || false}
                      options={PAGE_TYPES_OPTIONS}
                      placeholder="Select page type"
                      onChange={() => {
                        form.resetFields([
                          ['logoRedirection', 'slug'],
                          ['logoRedirection', 'url']
                        ]);
                      }}
                    />
                  </Form.Item>
                  {internalPageType && (
                    <>
                      {internalPageType === PAGE_TYPES.STATIC ? (
                        <Form.Item
                          label="Page"
                          name={['logoRedirection', 'slug']}
                          rules={[
                            {
                              required:
                                logoRedirectionEnabled &&
                                pageType === REDIRECTION_TYPE.INTERNAL,
                              message: 'Please select page!'
                            }
                          ]}
                        >
                          <AntdSelect
                            disabled={!logoRedirectionEnabled || false}
                            options={STATIC_PAGES}
                            onChange={(value) => {
                              form.setFieldValue(
                                ['logoRedirection', 'url'],
                                `/${value}`
                              );
                              form.validateFields(['logoRedirection', 'url']);
                            }}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label="Page"
                          name={['logoRedirection', 'slug']}
                          rules={[
                            {
                              required:
                                logoRedirectionEnabled &&
                                pageType === REDIRECTION_TYPE.INTERNAL,
                              message: 'Please select page!'
                            }
                          ]}
                        >
                          <Select
                            disabled={!logoRedirectionEnabled || false}
                            placeholder="Select"
                            query={GET_SLUGS}
                            variablesSelector={(filters) => ({
                              filter: {
                                ...filters,
                                pageType: internalPageType
                              }
                            })}
                            dataSelector={(data) => {
                              return (
                                data?.slugs?.slugs?.map(({ slug }) => ({
                                  label: `/${slug}`,
                                  value: slug
                                })) ?? []
                              );
                            }}
                            keys={{
                              data: 'slugs',
                              records: 'slugs',
                              count: 'count'
                            }}
                            onChange={({ value }) => {
                              form.setFieldValue(
                                ['logoRedirection', 'url'],
                                `/${value}`
                              );
                              form.validateFields(['logoRedirection', 'url']);
                            }}
                          />
                        </Form.Item>
                      )}
                    </>
                  )}
                </>
              )}
              <Form.Item
                label="URL"
                name={['logoRedirection', 'url']}
                rules={[
                  {
                    required: logoRedirectionEnabled,
                    message: 'Please enter URL!'
                  },
                  {
                    async validator(_, value) {
                      if (
                        pageType === REDIRECTION_TYPE.EXTERNAL &&
                        value &&
                        !REGEX?.WEB_URL?.test(value)
                      ) {
                        return Promise?.reject(
                          new Error('Should be a valid URL')
                        );
                      }
                      return Promise?.resolve();
                    }
                  }
                ]}
              >
                <Input
                  placeholder="Enter url"
                  readOnly={pageType === REDIRECTION_TYPE.INTERNAL}
                  disabled={
                    pageType === REDIRECTION_TYPE.INTERNAL ||
                    !logoRedirectionEnabled ||
                    false
                  }
                />
              </Form.Item>
            </fieldset>

            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <Permissions />

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPage;
