import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  ModuleFields,
  Permissions,
  SelectCollections,
  ShowFields,
  Switch
} from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  permissions: [],
  settings: {
    title: true,
    description: true,
    viewAll: true
  },
  config: {
    autoGenerateLimit: null,
    collection: null
  }
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.COLLECTION_ITEM_CAROUSEL,
      MODULE_TYPES.COLLECTION_ITEM_GRID,
      MODULE_TYPES.COLLECTION_ITEM_LIST
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.COLLECTION_ITEM_CAROUSEL,
      MODULE_TYPES.COLLECTION_ITEM_GRID,
      MODULE_TYPES.COLLECTION_ITEM_LIST
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.COLLECTION_ITEM_CAROUSEL,
      MODULE_TYPES.COLLECTION_ITEM_GRID,
      MODULE_TYPES.COLLECTION_ITEM_LIST
    ]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.COLLECTION_ITEM_CAROUSEL]: 'collectionItemCarouselModule',
  [MODULE_TYPES.COLLECTION_ITEM_GRID]: 'collectionItemGridModule',
  [MODULE_TYPES.COLLECTION_ITEM_LIST]: 'collectionItemListModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.COLLECTION_ITEM_CAROUSEL]: 'Collection Item Carousel Configs',
  [MODULE_TYPES.COLLECTION_ITEM_GRID]: 'Collection Item Grid Configs',
  [MODULE_TYPES.COLLECTION_ITEM_LIST]: 'Collection Item List Configs'
};

const { COLLECTION } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.COLLECTION_ITEM_CAROUSEL]: [COLLECTION],
  [MODULE_TYPES.COLLECTION_ITEM_GRID]: [COLLECTION],
  [MODULE_TYPES.COLLECTION_ITEM_LIST]: [COLLECTION]
};

function CollectionItemForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) {
  const [form] = Form.useForm();
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const isEdit = formType === FORM_TYPES.EDIT;
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;

      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          collection: defaultValues?.moduleData?.config?.collection
            ? {
                id: defaultValues?.moduleData?.config?.collection?.id ?? '',
                title:
                  defaultValues?.moduleData?.config?.collection?.title ?? '',
                url:
                  defaultValues?.moduleData?.config?.collection?.thumbnail
                    ?.url ?? ''
              }
            : null
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    isDefaultModule: isDefaultModule$,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && isDefaultModule$;

    const { collection, autoGenerateLimit, ...restConfig } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          collectionId: isDefault ? '*' : collection?.id ?? null,
          ...(autoGenerateLimit && {
            autoGenerateLimit: Number(autoGenerateLimit)
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === COLLECTION && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
              {isDefaultModule && (
                <Form.Item
                  label="No. of Auto generated collection items"
                  name={['config', 'autoGenerateLimit']}
                  rules={[
                    formValidatorRules?.number,
                    formValidatorRules?.maxNumberAllowed(
                      configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                    )
                  ]}
                >
                  <Input placeholder="Enter number" />
                </Form.Item>
              )}
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <Form.Item
                label="No. of Auto generated collection items"
                name={['config', 'autoGenerateLimit']}
                rules={[
                  formValidatorRules?.number,
                  formValidatorRules?.maxNumberAllowed(
                    configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                  )
                ]}
              >
                <Input placeholder="Enter number" />
              </Form.Item>
              <Form.Item
                label="Select Collection"
                name={['config', 'collection']}
              >
                <SelectCollections multiple={false} />
              </Form.Item>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
}

export default CollectionItemForm;
