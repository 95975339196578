import { gql } from '@apollo/client';

export const GET_POSTS = gql`
  query postsAdmin($filter: PostsFilter!, $sort: PostsSort) {
    postsAdmin(filter: $filter, sort: $sort) {
      count
      posts {
        id
        text
        createdBy {
          id
          profileImage
          firstName
          lastName
        }
        createdAt
        flagCount
        prayerCount
        likeCount
        viewCount
        commentCount
      }
    }
  }
`;

export const GET_POSTS_OPTIONS = gql`
  query postsAdmin($filter: PostsFilter!) {
    postsAdmin(filter: $filter) {
      count
      posts {
        id
        text
      }
    }
  }
`;
