import {
  DownOutlined,
  EditOutlined,
  IdcardOutlined,
  LoginOutlined,
  PlusOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Divider, Menu, Typography } from 'antd';
import { capitalize, forEach } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  APP,
  MODULES,
  ROLE_KEYS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useRedirectUser from '../../../hooks/useRedirectUser';
import { Select } from '../../../modules/videos/components/FormInputs';
import UserProfile from '../header/UserProfile';
import { GET_WORKSPACES_APPS } from './graphql/Queries';
import './sidebar.less';

const { Paragraph } = Typography;

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

function Sidebar({ location: { pathname }, history, collapsed }) {
  const {
    state: { workspace, app, currentUser: { roles = [] } = null },
    dispatch
  } = useContext(AppContext);
  const { redirectUser } = useRedirectUser();
  const [openKeys, setOpenKeys] = useState([]);
  const isAppAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAppEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.APP_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const subMenus = [
    {
      moduleKey: MODULES?.CONTENT_UNITS,
      routes: [
        ROUTES?.VIDEOS_MODULE,
        ROUTES?.PODCAST_MODULE,
        ROUTES?.FORMS,
        ROUTES?.ARTICLES_MODULE,
        ROUTES?.COMMUNITY_MODULE
      ]
    },
    {
      moduleKey: MODULES?.ASSETS,
      routes: [
        ROUTES?.MAIN,
        ROUTES?.AUDIO,
        ROUTES?.DOCUMENT,
        ROUTES?.VIDEOS,
        ROUTES?.TEXT,
        ROUTES?.ICONS
      ]
    },
    {
      moduleKey: MODULES?.LABELS,
      routes: [
        ROUTES?.TOPICS,
        ROUTES?.TAGS,
        ROUTES?.CONTRIBUTOR,
        ROUTES?.COLLECTIONS,
        ROUTES?.SOURCES
      ]
    },
    {
      moduleKey: MODULES?.REPORTS,
      routes: [ROUTES?.LEAD_GENS, ROUTES?.CONTACT_INQUIRY, ROUTES?.EXPORT_JOBS]
    },
    // TODO: commented for future use
    // {
    //   moduleKey: MODULES?.MODERATION,
    //   routes: [ROUTES?.POSTS]
    // },
    {
      moduleKey: MODULES?.UI_CONFIGURATION,
      routes: [ROUTES?.MENUS, ROUTES?.PAGES_MODULE, ROUTES?.UI_LABELS]
    }
  ];

  const getOpenKeys = () => {
    const openKeysClone = openKeys;
    forEach(subMenus, (item) => {
      forEach(item?.routes, (route) => {
        if (
          route !== ROUTES?.MAIN &&
          pathname?.includes(route) &&
          !openKeysClone?.includes(item?.moduleKey)
        ) {
          openKeysClone?.push(item?.moduleKey);
          setOpenKeys(openKeysClone);
        }
      });
    });
  };

  useEffect(() => {
    getOpenKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    dispatch({
      type: 'SET_WORKSPACE_APPS_LOADING',
      data: isAppAllowed
    });
  }, [isAppAllowed]);

  const ref = useRef();

  const onMenuSelect = ({ key }) => {
    history?.push(key);
  };

  useEffect(() => {
    if (workspace && ref.current?.refetch) {
      ref.current?.refetch();
    }
  }, [workspace]);

  function getItem({ label, key, icon, children, type }) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }
  const isContentManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isAssetManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isLabelManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isPlatformManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isUserManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isRoleManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isReportManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const uiConfigManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const uiPageManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const menuItems = [
    isContentManagementAllowed &&
      getItem({
        label: MODULES?.CONTENT_UNITS,
        key: MODULES?.CONTENT_UNITS,
        children: [
          getItem({
            label: MODULES?.VIDEOS,
            key: ROUTES?.VIDEOS_MODULE
          }),
          getItem({
            label: MODULES?.PODCAST,
            key: ROUTES?.PODCAST_MODULE
          }),
          getItem({
            label: MODULES?.FORMS,
            key: ROUTES?.FORMS
          }),
          getItem({ label: MODULES?.ARTICLES, key: ROUTES?.ARTICLES_MODULE }),
          getItem({
            label: MODULES?.COMMUNITIES,
            key: ROUTES?.COMMUNITY_MODULE
          })
        ]
      }),
    isAssetManagementAllowed &&
      getItem({
        label: MODULES?.ASSETS,
        key: MODULES?.ASSETS,
        children: [
          getItem({ label: MODULES?.IMAGES, key: ROUTES?.IMAGES }),
          getItem({ label: MODULES?.VIDEOS, key: ROUTES?.VIDEOS }),
          getItem({ label: MODULES?.DOCUMENT, key: ROUTES?.DOCUMENT }),
          getItem({ label: MODULES?.AUDIO, key: ROUTES?.AUDIO }),
          getItem({ label: MODULES?.TEXT, key: ROUTES?.TEXT }),
          getItem({ label: MODULES?.ICONS, key: ROUTES?.ICONS })
        ]
      }),
    isLabelManagementAllowed &&
      getItem({
        label: MODULES?.LABELS,
        key: MODULES?.LABELS,
        children: [
          getItem({ label: MODULES?.TOPICS, key: ROUTES?.TOPICS }),
          getItem({ label: MODULES?.TAGS, key: ROUTES?.TAGS }),
          getItem({ label: MODULES?.CONTRIBUTOR, key: ROUTES?.CONTRIBUTOR }),
          getItem({ label: MODULES?.COLLECTIONS, key: ROUTES?.COLLECTIONS }),
          getItem({ label: MODULES?.SOURCES, key: ROUTES?.SOURCES })
        ]
      }),
    isPlatformManagementAllowed &&
      getItem({
        label: MODULES?.PLATFORMS,
        key: ROUTES?.PLATFORMS
      }),
    isUserManagementAllowed &&
      getItem({
        label: MODULES?.USERS,
        key: ROUTES?.USERS
      }),
    isUserManagementAllowed &&
      getItem({
        label: MODULES?.WORKSPACE_USERS,
        key: ROUTES?.WORKSPACE_USERS
      }),
    isRoleManagementAllowed &&
      getItem({
        label: MODULES?.ROLES,
        key: ROUTES?.ROLES
      }),
    isUserManagementAllowed &&
      getItem({
        label: MODULES?.DONATIONS,
        key: ROUTES?.DONATIONS
      }),

    isReportManagementAllowed &&
      getItem({
        label: MODULES?.REPORTS,
        key: MODULES?.REPORTS,
        children: [
          getItem({
            label: MODULES?.LEAD_GENS,
            key: ROUTES?.LEAD_GENS
          }),
          getItem({
            label: MODULES?.CONTACT_INQUIRY,
            key: ROUTES?.CONTACT_INQUIRY
          }),
          getItem({
            label: MODULES?.EXPORT_JOBS,
            key: ROUTES?.EXPORT_JOBS
          })
        ]
      })
    // TODO: commented for future use
    // TODO: Handle permissions once added in BE
    // getItem({
    //   label: MODULES?.MODERATION,
    //   key: MODULES?.MODERATION,
    //   children: [
    //     getItem({
    //       label: MODULES?.POSTS,
    //       key: ROUTES?.POSTS
    //     })
    //   ]
    // })
  ];

  const appMenuItems = [
    (uiConfigManagementAllowed || uiPageManagementAllowed) &&
      getItem({
        label: MODULES?.UI_CONFIGURATION,
        key: MODULES?.UI_CONFIGURATION,
        children: [
          uiConfigManagementAllowed &&
            getItem({
              label: MODULES?.MENUS,
              key: ROUTES?.MENUS
            }),
          uiPageManagementAllowed &&
            getItem({
              label: MODULES?.PAGES,
              key: ROUTES?.PAGES_MODULE
            }),
          uiConfigManagementAllowed &&
            getItem({
              label: MODULES?.UI_LABELS,
              key: ROUTES?.UI_LABELS
            })
        ]
      })
  ];

  const profileItems = [
    getItem({
      label: <UserProfile collapsed={collapsed} />,
      key: MODULES?.PROFILE,
      children: [
        [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(roles?.[0]) &&
          getItem({
            label: (
              <Link to={ROUTES?.ADMIN_USERS}>
                {capitalize(MODULES?.ADMINS)}
              </Link>
            ),
            key: ROUTES?.ADMIN_USERS,
            icon: <UserOutlined />
          }),
        getItem({
          label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
          key: ROUTES?.PROFILE,
          icon: <IdcardOutlined />
        }),
        getItem({
          label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
          key: ROUTES?.LOGOUT,
          icon: <LoginOutlined />
        })
      ]
    })
  ];

  const handleAppChange = (_, newValue) => {
    dispatch({ type: 'SET_APP', data: newValue });
    const pathNameArray = pathname?.split('/');
    if (pathNameArray?.length > 2) {
      if (pathNameArray?.[1] === 'apps') {
        const getRoute = redirectUser();
        if (getRoute === pathname) {
          // eslint-disable-next-line no-undef
          window.location.reload();
        } else {
          history.push(getRoute);
        }
      } else {
        history?.push(`/${pathNameArray?.[1]}`);
      }
    } else {
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-scroll">
        {workspace && (
          <Menu
            className="menu-bar"
            theme="light"
            mode="inline"
            selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
            defaultSelectedKeys={[ROUTES?.MAIN]}
            items={menuItems}
            inlineIndent={0}
            openKeys={openKeys}
            onOpenChange={(openKeysArray) => setOpenKeys(openKeysArray)}
            onClick={onMenuSelect}
          />
        )}
        {isAppAllowed && (
          <>
            <Divider className="mt-0" />
            <Select
              ref={ref}
              className="dropdown-padding"
              popupMatchSelectWidth={false}
              variablesSelector={variablesSelector}
              onChange={handleAppChange}
              value={app}
              placeholder="App"
              query={GET_WORKSPACES_APPS}
              dropdownRender={(menu) => {
                return (
                  <>
                    {isAppEditAllowed && (
                      <Button
                        className="select-add-btn"
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          history?.push(`${ROUTES?.APPS}/add`);
                        }}
                      >
                        Add App
                      </Button>
                    )}
                    {menu}
                  </>
                );
              }}
              optionRender={(option) => (
                <div className="d-flex justify-between align-center w-full">
                  {option?.label}
                  <div className="d-flex align-center">
                    {isAppEditAllowed && (
                      <Button
                        className="white-btn"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          history?.push(
                            `${ROUTES.APPS}/${option?.data?.id}/edit`
                          );
                        }}
                        icon={<EditOutlined />}
                      />
                    )}
                    <Paragraph
                      className="copy-icon"
                      copyable={{
                        text: option?.value,
                        tooltips: 'Copy App UUID'
                      }}
                    />
                  </div>
                </div>
              )}
              dataSelector={(data) =>
                data?.workspaceApps?.workspaceApps?.map(
                  ({ uuid, name, id }) => ({
                    label: name,
                    value: uuid,
                    id
                  })
                ) || []
              }
              keys={{
                data: 'workspaceApps',
                records: 'workspaceApps',
                count: 'count'
              }}
              suffixIcon={collapsed ? null : <DownOutlined />}
              queryOptions={{
                skip: !workspace,
                onCompleted: ({ workspaceApps }) => {
                  const firstApp = workspaceApps?.workspaceApps?.[0];
                  const selectedApp =
                    // eslint-disable-next-line no-undef
                    JSON?.parse(localStorage.getItem(APP)) ||
                    (firstApp
                      ? {
                          label: firstApp?.name,
                          value: firstApp?.uuid,
                          id: firstApp?.id
                        }
                      : null);
                  dispatch({ type: 'SET_APP', data: selectedApp });
                  dispatch({
                    type: 'SET_WORKSPACE_APPS_LOADING',
                    data: false
                  });
                },
                onError() {
                  dispatch({
                    type: 'SET_WORKSPACE_APPS_LOADING',
                    data: false
                  });
                }
              }}
            />
            <Divider />

            {app && (
              <Menu
                className="menu-bar"
                theme="light"
                mode="inline"
                selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
                items={appMenuItems}
                inlineIndent={0}
                openKeys={openKeys}
                onOpenChange={(openKeysArray) => setOpenKeys(openKeysArray)}
                onClick={onMenuSelect}
              />
            )}
          </>
        )}
      </div>
      <div className="profile-menu">
        <Menu
          className="profile-bar"
          theme="light"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          items={profileItems}
        />
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
