import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { Select } from '../../../../videos/components/FormInputs';
import { GET_TAGS } from '../../../../videos/graphql/Queries';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  permissions: [],
  settings: {
    title: true,
    description: true,
    tagTitle: true,
    viewAll: true
  },
  config: {
    autoGenerate: false,
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    tags: type === MODULE_TYPES.FEATURED_TAG ? null : []
  }
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.TAG_CLOUD, MODULE_TYPES.TAG_CAROUSEL]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.TAG_CLOUD, MODULE_TYPES.TAG_CAROUSEL]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [MODULE_TYPES.TAG_CLOUD, MODULE_TYPES.TAG_CAROUSEL]
  },
  {
    name: 'tagTitle',
    label: 'Tag Title',
    allowedTypes: [MODULE_TYPES.FEATURED_TAG]
  }
];

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM }
];

const MODULE_KEYS = {
  [MODULE_TYPES.TAG_CAROUSEL]: 'tagCarouselModule',
  [MODULE_TYPES.TAG_CLOUD]: 'tagCloudModule',
  [MODULE_TYPES.FEATURED_TAG]: 'featuredTagModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.TAG_CAROUSEL]: 'Tag Carousel Configs',
  [MODULE_TYPES.TAG_CLOUD]: 'Tag Cloud Configs',
  [MODULE_TYPES.FEATURED_TAG]: 'Feature Tag Configs'
};

const { TAG } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.FEATURED_TAG]: [TAG]
};

const TagForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) => {
  const [form] = Form.useForm();
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const isMultiTag = type !== MODULE_TYPES.FEATURED_TAG;
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const { autoGenerate } = configProps ?? initialValues.config;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.tagAutoGenerateSortBy || null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          ...(isMultiTag && {
            tags:
              defaultValues?.moduleData?.config?.tags?.map(({ id, name }) => ({
                label: name,
                value: id
              })) || []
          }),
          ...(!isMultiTag && {
            tags: defaultValues?.moduleData?.config?.tag
              ? {
                  name: defaultValues?.moduleData?.config?.tag?.name,
                  value: defaultValues?.moduleData?.config?.tag?.id
                }
              : null
          })
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiTag
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const isDefault = allowedTemplateConfig && isDefaultModule;

    // eslint-disable-next-line no-shadow
    const { tags, autoGenerate, autoGenerateLimit, ...restConfig } =
      config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          autoGenerate,
          ...restConfig,
          ...(isDefault
            ? {
                tagId: '*'
              }
            : {
                ...(!autoGenerate &&
                  isMultiTag && {
                    tags:
                      tags.map(({ value }, i) => ({
                        tagId: value,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!autoGenerate &&
                  !isMultiTag && {
                    tagId: tags?.value ?? ''
                  })
              }),
          ...(autoGenerateLimit && {
            autoGenerateLimit: Number(autoGenerateLimit)
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === TAG && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                <Form.Item
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                >
                  <Switch
                    label={
                      isMultiTag ? 'Auto Generated Tags' : 'Auto Generated Tag'
                    }
                  />
                </Form.Item>
                {autoGenerate ? (
                  <>
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_SORT_BY_OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                    {isMultiTag && (
                      <Form.Item
                        label="No. of Auto generated Tags"
                        name={['config', 'autoGenerateLimit']}
                        rules={[
                          formValidatorRules?.number,
                          formValidatorRules?.maxNumberAllowed(
                            configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                          )
                        ]}
                      >
                        <Input placeholder="Enter number" />
                      </Form.Item>
                    )}
                  </>
                ) : (
                  <Form.Item
                    label={isMultiTag ? 'Select Tags' : 'Select Tag'}
                    name={['config', 'tags']}
                    extra={isMultiTag ? 'Select items in order you want' : ''}
                  >
                    <Select
                      multiple={isMultiTag}
                      placeholder={isMultiTag ? 'Select tags' : 'Select tag'}
                      query={GET_TAGS}
                      variablesSelector={(filter) => ({ filter })}
                      dataSelector={(data) =>
                        data?.tagsAdmin?.tags?.map(({ id, name }) => ({
                          label: name,
                          value: id
                        })) || []
                      }
                      keys={{
                        data: 'tagsAdmin',
                        records: 'tags',
                        count: 'count'
                      }}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default TagForm;
