import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_OPTIONS,
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectCommunities } from '../../../../labels/collections/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  permissions: [],
  settings: {
    title: true,
    description: true,
    communityDescription: true,
    communityMemberCount: true,
    locationSort: true,
    sortByTag: true
  },
  config: {
    autoGenerate: false,
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    autoGenerateByMember: false,
    communities: type === MODULE_TYPES.FEATURED_COMMUNITY ? null : []
  }
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.COMMUNITY_CAROUSEL,
      MODULE_TYPES.FEATURED_COMMUNITY,
      MODULE_TYPES.COMMUNITY_GRID
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.COMMUNITY_CAROUSEL,
      MODULE_TYPES.FEATURED_COMMUNITY,
      MODULE_TYPES.COMMUNITY_GRID
    ]
  },
  {
    name: 'communityDescription',
    label: 'Community Description',
    allowedTypes: [
      MODULE_TYPES.COMMUNITY_CAROUSEL,
      MODULE_TYPES.FEATURED_COMMUNITY
    ]
  },
  {
    name: 'communityMemberCount',
    label: 'Members Count',
    allowedTypes: [
      MODULE_TYPES.COMMUNITY_CAROUSEL,
      MODULE_TYPES.FEATURED_COMMUNITY,
      MODULE_TYPES.COMMUNITY_GRID
    ]
  },
  {
    name: 'locationSort',
    label: 'Sort By Location',
    allowedTypes: [MODULE_TYPES.COMMUNITY_GRID]
  },
  {
    name: 'sortByTag',
    label: 'Sort By Tag',
    allowedTypes: [MODULE_TYPES.COMMUNITY_GRID]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.COMMUNITY_CAROUSEL]: 'communityCarouselModule',
  [MODULE_TYPES.COMMUNITY_GRID]: 'communityGridModule',
  [MODULE_TYPES.FEATURED_COMMUNITY]: 'featuredCommunityModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.COMMUNITY_CAROUSEL]: 'Community Carousel Configs',
  [MODULE_TYPES.COMMUNITY_GRID]: 'Community Grid Configs',
  [MODULE_TYPES.FEATURED_COMMUNITY]: 'Featured Community Configs'
};

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.FEATURED_COMMUNITY]: [PAGE_TYPES.COMMUNITY]
};

export default function CommunityForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);

  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const multiple = type !== MODULE_TYPES.FEATURED_COMMUNITY;
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const { autoGenerate } = configProps ?? initialValues.config;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.communityAutoGenerateSortBy ||
            null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          autoGenerateByMember: Boolean(
            defaultValues?.moduleData?.config?.autoGenerateByMember
          ),
          ...(multiple && {
            communities:
              defaultValues?.moduleData?.config?.communities?.map(
                ({ id, title, imageUrl }) => ({
                  id,
                  title,
                  url: imageUrl ?? ''
                })
              ) || []
          }),
          ...(!multiple && {
            communities: defaultValues?.moduleData?.config?.community
              ? {
                  id: defaultValues?.moduleData?.config?.community?.id,
                  title: defaultValues?.moduleData?.config?.community?.title,
                  url: defaultValues?.moduleData?.config?.community?.imageUrl
                }
              : null
          })
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues, multiple]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const isDefault = allowedTemplateConfig && isDefaultModule;

    // eslint-disable-next-line no-shadow
    const { communities, autoGenerate, autoGenerateLimit, ...restConfig } =
      config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          autoGenerate,
          ...restConfig,
          ...(isDefault
            ? {
                communityId: '*'
              }
            : {
                ...(!autoGenerate &&
                  multiple && {
                    communities:
                      communities.map(({ id }, i) => ({
                        communityId: id,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!autoGenerate &&
                  !multiple && {
                    communityId: communities?.id ?? ''
                  })
              }),
          ...(autoGenerateLimit && {
            autoGenerateLimit: Number(autoGenerateLimit)
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  const OPTIONS = useMemo(
    () =>
      AUTO_GENERATED_SORT_BY_OPTIONS.filter(
        ({ value }) => value !== AUTO_GENERATED_SORT_BY_TYPES.VIEWS
      ),
    []
  );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === PAGE_TYPES.COMMUNITY && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                <Form.Item
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                >
                  <Switch
                    label={
                      multiple
                        ? 'Auto Generated Communities'
                        : 'Auto Generated Community'
                    }
                  />
                </Form.Item>
                {autoGenerate ? (
                  <>
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                    {type !== MODULE_TYPES.FEATURED_COMMUNITY && (
                      <Form.Item
                        name={['config', 'autoGenerateByMember']}
                        valuePropName="checked"
                      >
                        <Switch label="Auto generate by members" />
                      </Form.Item>
                    )}
                    {multiple && (
                      <Form.Item
                        label="No. of Auto generated Communities"
                        name={['config', 'autoGenerateLimit']}
                        rules={[
                          formValidatorRules?.number,
                          formValidatorRules?.maxNumberAllowed(
                            configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                          )
                        ]}
                      >
                        <Input placeholder="Enter number" />
                      </Form.Item>
                    )}
                  </>
                ) : (
                  <Form.Item
                    label={multiple ? 'Select Communities' : 'Select Community'}
                    name={['config', 'communities']}
                    extra={multiple ? 'Select items in order you want' : ''}
                  >
                    <SelectCommunities multiple={multiple} />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
}
