import React from 'react';
import { MODULES } from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import PreviewBar from '../component/Previewbar';
import LayoutRow from '../component/layout/LayoutRow';
import LeftPanel from './component/pageModules/LeftPanel';
import PreviewWrapper from './component/pageModules/Preview';
import RightPanel from './component/pageModules/RightPanel';
import EditPageProvider from './context/EditPageProvider';
import PreviewPageProvider from './context/PreviewPageProvider';

const EditPage = () => {
  return (
    <>
      <PageHeader menu={MODULES?.UI_CONFIGURATION} />
      <div className="page-wrapper">
        <div className="page-wrapper-body edit-page">
          <LayoutRow>
            <LeftPanel />
            <RightPanel />
            <PreviewBar>
              <PreviewWrapper />
            </PreviewBar>
          </LayoutRow>
        </div>
      </div>
    </>
  );
};

const wrapper = () => {
  return (
    <PreviewPageProvider>
      <EditPageProvider>
        <EditPage />
      </EditPageProvider>
    </PreviewPageProvider>
  );
};

export default wrapper;
