import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { authClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import './auth.less';
import { UPDATE_PASSWORD, VERIFY_TOKEN } from './graphql/Mutations';

const { required } = formValidatorRules;

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const [resetPassword, { loading: reseting }] = useMutation(UPDATE_PASSWORD, {
    client: authClient,
    onError() {} // Always write this method for error handling in all mutation.
  });
  const [verifyToken] = useMutation(VERIFY_TOKEN, {
    client: authClient,
    onError() {} // Always write this method for error handling in all mutation.
  });

  const params = new URLSearchParams(location?.search);
  const token = params?.get('token') || location?.state?.token;
  const uid = params?.get('uid') || location?.state?.uid;

  useEffect(() => {
    if (location?.pathname === ROUTES?.RESET) {
      verifyToken({
        variables: {
          data: {
            uid,
            token
          }
        }
      }).catch((e) => e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location?.pathname]);

  const onFinish = async ({ password }) => {
    try {
      const response = await resetPassword({
        variables: { data: { uid, token, password: password?.trim() } }
      });
      if (response) {
        history?.replace(ROUTES?.LOGIN);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="login-section">
      <div className="login-img">
        <h1>Media Command Center</h1>
        <p>Administration</p>
      </div>
      <div className="login-wrap d-flex align-center justify-center">
        <Card className="full-width">
          <div className="text-center">
            <h2 className="text-center">Reset Password</h2>
            <p className="text-center">Enter a new password for your account</p>
          </div>
          <Spin spinning={reseting}>
            <Form
              name="reset-password"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="password"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item
                name="retype-password"
                rules={[
                  { required, message: 'Please enter confirm password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value !== getFieldValue('password')) {
                        return Promise?.reject(
                          new Error('Passwords do not match')
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter confirm password"
                />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  onClick={() => {
                    history?.push(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
