import { Button, Tooltip } from 'antd';
import { FileArrowDown } from 'phosphor-react';
import React from 'react';
import api from '../../common/api';
import { LIST_TYPES, MODULES } from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { GET_NEWS_LETTER_KEYS } from './graphql/Queries';

const dataSelector = ({ dataCollectionKeys }) => ({
  data: dataCollectionKeys?.map((key) => ({ id: key })) ?? [],
  count: dataCollectionKeys?.length ?? 0
});

const Action = ({ id }) => {
  const handleDownload = () => {
    api({
      url: `/api/v1/common/download-news-letter-subscriptions?key=${id}`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      // eslint-disable-next-line no-undef
      const doc = document;
      const link = doc.createElement('a');
      link.href = href;
      link.setAttribute('download', `${id}.csv`);
      doc.body.appendChild(link);
      link.click();
      doc.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };
  return (
    <Tooltip title="Download">
      <Button
        className="text-btn d-flex align-center justify-center"
        type="text"
        onClick={handleDownload}
      >
        <FileArrowDown size={22} />
      </Button>
    </Tooltip>
  );
};

const columns = [
  {
    title: 'Key',
    dataIndex: 'id',
    width: '10%'
  },
  {
    title: 'Download',
    dataIndex: 'id',
    width: '90%',
    render: (_, record) => <Action {...record} />
  }
];

const NewsLetters = () => {
  return (
    <>
      <PageHeader menu={MODULES?.REPORTS} />
      <PageList
        listMode={LIST_TYPES.TABLE}
        query={GET_NEWS_LETTER_KEYS}
        variablesSelector={() => ({})}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={20}
      />
    </>
  );
};

export default NewsLetters;
