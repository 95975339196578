import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Select, Tooltip } from 'antd';
import moment from 'moment';
import { Export } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from '../../AppContext';
import { authClient } from '../../apollo';
import {
  DATE_FORMAT,
  LIST_TYPES,
  ROUTES,
  USERS_SORT_FIELD,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import Avatar from '../../components/Avatar';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { USERS_FORGOT_PASSWORD } from '../auth/graphql/Mutations';
import { REQUEST_EXPORT_USERS } from './graphql/Mutations';
import { GET_USERS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.users?.users ?? [],
  count: data?.users?.count ?? 0
});

const variablesSelector = ({ offset, sortField, sortOrder, ...rest }) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const ResetPassword = ({ email }) => {
  const { state } = useApp();
  const [forgotPassword, { loading }] = useMutation(USERS_FORGOT_PASSWORD, {
    client: authClient
  });

  const handleClick = () => {
    forgotPassword({
      variables: {
        data: {
          email,
          appId: state?.app?.id
        }
      }
    });
  };

  return (
    <Button
      loading={loading}
      type="text"
      className="text-btn"
      onClick={handleClick}
    >
      Send Reset
    </Button>
  );
};

const Action = ({ id }) => {
  const history = useHistory();
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const handleEdit = () => {
    history.push(`${ROUTES?.USERS}/${id}/edit`);
  };
  return (
    <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} User`}>
      <Button type="text" className="text-btn" onClick={handleEdit}>
        {isViewOnly ? <EyeFilled /> : <EditOutlined />}
      </Button>
    </Tooltip>
  );
};

const columns = ({ isAddEditAllowed }) => {
  return [
    {
      title: 'Avatar',
      dataIndex: 'profileImage',
      width: 100,
      render: (_, { firstName, lastName, profileImage }) => (
        <Avatar src={profileImage} firstName={firstName} lastName={lastName} />
      )
    },
    {
      title: 'F name',
      dataIndex: 'firstName',
      ellipsis: true
    },
    {
      title: 'L name',
      dataIndex: 'lastName',
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      ellipsis: true,
      render: (value) => (value ? 'Active' : 'Inactive')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 300,
      render: (value) => <a href={`mailto:${value}`}>{value}</a>
    },
    isAddEditAllowed && {
      title: 'Password',
      dataIndex: 'email',
      render: (value) => <ResetPassword email={value} />
    },
    {
      title: 'Joined',
      dataIndex: 'createdAt',
      render: (value) => moment(value).format(DATE_FORMAT)
    },
    {
      title: 'Attributes',
      dataIndex: 'permissions',
      render: (value) => value?.join(',')
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (value) => <Action id={value} />
    }
  ];
};

const USER_TYPES = {
  ALL: 'ALL',
  LEGACY: 'LEGACY',
  NON_LEGACY: 'NON_LEGACY',
  MIGRATED: 'MIGRATED',
  NOT_MIGRATED: 'NOT_MIGRATED'
};

const USER_TYPE_FILTERS = {
  [USER_TYPES.LEGACY]: { legacyUsers: true },
  [USER_TYPES.NON_LEGACY]: { legacyUsers: false },
  [USER_TYPES.MIGRATED]: { legacyUsers: true, migratedAccount: true },
  [USER_TYPES.NOT_MIGRATED]: { legacyUsers: true, migratedAccount: false }
};

const USER_TYPES_OPTIONS = [
  {
    label: 'All',
    value: USER_TYPES.ALL
  },
  {
    label: 'Legacy',
    value: USER_TYPES.LEGACY
  },
  {
    label: 'Non Legacy',
    value: USER_TYPES.NON_LEGACY
  },
  {
    label: 'Migrated',
    value: USER_TYPES.MIGRATED
  },
  {
    label: 'Not Migrated',
    value: USER_TYPES.NOT_MIGRATED
  }
];

const Users = ({ history }) => {
  const [type, setType] = useState(USER_TYPES.ALL);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isExportAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: USERS_SORT_FIELD,
      field: USERS_SORT_FIELD[0].value
    }
  });

  const [exportUsers, { loading: isExporting }] = useMutation(
    REQUEST_EXPORT_USERS
  );

  const handleShowModal = () => {
    history.push(`${ROUTES?.USERS}/add`);
  };

  const handleTypeChange = (value) => setType(value);

  const filters = useMemo(
    () => ({ ...filterProps.filters, ...USER_TYPE_FILTERS[type] }),
    [filterProps.filters, type]
  );

  const handleExport = () => {
    const {
      sortField: _sortField,
      sortOrder: _sortOrder,
      ...payload
    } = filters;

    exportUsers({
      variables: {
        filter: payload
      }
    });
  };

  return (
    <>
      <PageHeader
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false
        }}
        SearchProps={{ placeholder: 'Search Users' }}
      >
        <Select
          value={type}
          popupMatchSelectWidth={false}
          placeholder="Select type"
          options={USER_TYPES_OPTIONS}
          onChange={handleTypeChange}
        />
        {isExportAllowed && (
          <Tooltip title="Export Users" placement="left">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<Export size={24} />}
              onClick={handleExport}
              loading={isExporting}
            />
          </Tooltip>
        )}
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_USERS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props, isAddEditAllowed })?.filter(
              (item) => item !== false
            )
        }}
        limit={20}
      />
    </>
  );
};

export default Users;
