import { useLazyQuery, useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MAX_LENGTHS,
  PROVIDER_TYPES_OPTIONS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../videos/components/FormInputs';
import { PROVIDER_TYPE_QUERY_KEY, getProviderType } from './Platforms';
import {
  CREATE_PLATFORM,
  UPDATE_WORKSPACE_PLATFORM
} from './graphql/Mutations';
import { GET_PLATFORM, GET_PROVIDERS } from './graphql/Queries';

const TYPES = {
  BOOLEAN: 'boolean',
  STRING: 'string',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password'
};

const initialValues = (providerType) => ({
  description: '',
  name: '',
  provider: null,
  type: providerType,
  configs: {}
});

const getConfigValues = (configs = []) => {
  const configDefaultValue = {};
  configs?.forEach((config) => {
    configDefaultValue[config.key] = config?.defaultValue ?? '';
  });
  return configDefaultValue;
};

function AddEditPlatform({ location, history, match }) {
  const providerType = useMemo(() => {
    const params = new URLSearchParams(location?.search);
    return getProviderType(params.get(PROVIDER_TYPE_QUERY_KEY));
  }, [location]);
  const [configFields, setConfigFields] = useState([]);

  const { platformId } = match?.params || {};
  const isEdit = !!platformId;
  const [getPlatform, { loading: fetchingDetails }] = useLazyQuery(
    GET_PLATFORM,
    {
      fetchPolicy: 'network-only'
    }
  );
  const [addUpdatePlatform, { loading }] = useMutation(
    isEdit ? UPDATE_WORKSPACE_PLATFORM : CREATE_PLATFORM
  );

  const [form] = Form.useForm();
  const type = Form.useWatch(['type'], form);

  useEffect(() => {
    if (isEdit && !!platformId) {
      getPlatform({
        variables: {
          where: {
            id: platformId,
            type: getProviderType(providerType)
          }
        }
      }).then(({ data, error }) => {
        if (!error && !!data) {
          form.setFieldsValue({
            name: data?.workspacePlatform?.name ?? '',
            description: data?.workspacePlatform?.description ?? '',
            type:
              data?.workspacePlatform?.type || getProviderType(providerType),
            provider: data?.workspacePlatform?.provider
              ? {
                  label: data?.workspacePlatform?.provider?.name,
                  value: data?.workspacePlatform?.provider?.key
                }
              : null,
            configs: data?.workspacePlatform?.config
          });
          setConfigFields(data?.workspacePlatform?.provider?.config);
        }
      });
    }
  }, [isEdit, platformId, form]);

  const handleClose = () => {
    const newParams = new URLSearchParams(location?.search);
    newParams.set(PROVIDER_TYPE_QUERY_KEY, getProviderType(providerType));
    history.push({
      pathname: `${ROUTES?.PLATFORMS}`,
      search: newParams.toString()
    });
  };

  const handleSubmit = ({ provider, configs, type: type$, ...rest }) => {
    const payload = {
      config: configs,
      ...(!isEdit && {
        type: type$,
        providerKey: provider?.value
      }),
      ...rest
    };

    addUpdatePlatform({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: platformId,
            type: type$
          }
        })
      }
    }).then(() => {
      handleClose();
    });
  };

  const variablesSelector = useCallback(
    ({ skip, limit }) => ({
      filter: {
        skip,
        limit,
        type
      }
    }),
    [type]
  );

  const handleProviderChange = (_, value) => {
    const { configs = [] } = value || {};
    setConfigFields(configs);
    const configDefaultValue = getConfigValues(configs);
    form.setFieldValue('configs', configDefaultValue);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            disabled={isViewOnly || fetchingDetails}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues(providerType)}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="name"
              label="Name"
              required
              rules={[
                formValidatorRules?.required('Please enter name'),
                formValidatorRules?.maxLength(MAX_LENGTHS?.NAME)
              ]}
            >
              <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS?.DESCRIPTION)]}
            >
              <Input.TextArea rows={3} placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              name="type"
              label="Provider Type"
              rules={[
                {
                  required: true,
                  message: 'Please select provider type'
                }
              ]}
            >
              <AntdSelect
                disabled={isEdit}
                placeholder="Select provider type"
                popupMatchSelectWidth={false}
                options={PROVIDER_TYPES_OPTIONS}
                onChange={(_, value) => {
                  form.resetFields(['configs', 'provider']);
                  const { configs = [] } = value || {};
                  setConfigFields(configs);
                  const configDefaultValue = getConfigValues(configs);
                  form.setFieldValue('configs', configDefaultValue);
                }}
              />
            </Form.Item>
            <Form.Item
              name="provider"
              label="Provider"
              rules={[
                {
                  required: true,
                  message: 'Please select provider'
                }
              ]}
            >
              <Select
                disabled={isEdit}
                searchable={false}
                popupMatchSelectWidth={false}
                variablesSelector={variablesSelector}
                placeholder="Select provider"
                query={GET_PROVIDERS}
                onChange={handleProviderChange}
                dataSelector={(data) =>
                  data?.providersAdmin?.providers?.map(
                    ({ key, name, config }) => ({
                      label: name,
                      value: key,
                      configs: config || []
                    })
                  ) ?? []
                }
                keys={{
                  data: 'providersAdmin',
                  records: 'providers',
                  count: 'count'
                }}
                queryOptions={{
                  skip: !type
                }}
              />
            </Form.Item>
            {configFields?.map(
              ({ key, label, type: inputType, isRequired, isReadOnly }) => {
                return (
                  <Form.Item
                    key={key}
                    name={['configs', key]}
                    {...(isRequired &&
                      inputType !== TYPES.BOOLEAN && {
                        required: true,
                        rules: [
                          formValidatorRules?.required(
                            `Please enter ${label.toLowerCase()}!`
                          )
                        ]
                      })}
                    {...(inputType === TYPES.BOOLEAN
                      ? {
                          valuePropName: 'checked'
                        }
                      : {
                          label
                        })}
                  >
                    {
                      {
                        [TYPES.STRING]: (
                          <Input
                            placeholder={`Enter ${label.toLowerCase()}`}
                            readOnly={isReadOnly}
                            disabled={isReadOnly}
                          />
                        ),
                        [TYPES.PASSWORD]: (
                          <Input.Password
                            placeholder={`Enter ${label.toLowerCase()}`}
                            readOnly={isReadOnly}
                            disabled={isReadOnly}
                          />
                        ),
                        [TYPES.TEXTAREA]: (
                          <Input.TextArea
                            rows={3}
                            placeholder={`Enter ${label.toLowerCase()}`}
                            readOnly={isReadOnly}
                            disabled={isReadOnly}
                          />
                        ),
                        [TYPES.NUMBER]: (
                          <Input
                            type="number"
                            placeholder={`Enter ${label.toLowerCase()}`}
                            readOnly={isReadOnly}
                            disabled={isReadOnly}
                          />
                        ),
                        [TYPES.BOOLEAN]: (
                          <Switch label={label} disabled={isReadOnly} />
                        )
                      }[inputType]
                    }
                  </Form.Item>
                );
              }
            )}
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddEditPlatform;
