import React from 'react';
import { MODULES } from '../../../common/constants';
import PageHeader from '../../../components/PageHeader';
import CommonVideoForm from './CommonVideoForm';

const AddEditVideo = () => {
  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <CommonVideoForm />
        </div>
      </div>
    </>
  );
};

export default AddEditVideo;
