import React from 'react';
import { MODULES } from '../../../common/constants';
import PageHeader from '../../../components/PageHeader';
import CommonTextForm from './CommonTextForm';

const AddEditText = () => {
  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <CommonTextForm />
        </div>
      </div>
    </>
  );
};

export default AddEditText;
