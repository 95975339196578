import {
  DownOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Layout, Typography } from 'antd';
import { find, isEmpty, uniqBy } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import '../assets/fonts/FoundersGroteskMono-Bold.woff';
import '../assets/fonts/FoundersGroteskMono-Bold.woff2';
import '../assets/fonts/FoundersGroteskMono-Light.woff';
import '../assets/fonts/FoundersGroteskMono-Light.woff2';
import '../assets/fonts/FoundersGroteskMono-Medium.woff';
import '../assets/fonts/FoundersGroteskMono-Medium.woff2';
import '../assets/fonts/FoundersGroteskMono-Regular.woff';
import '../assets/fonts/FoundersGroteskMono-Regular.woff2';
import '../assets/fonts/FoundersGroteskMono-Semibold.woff';
import '../assets/fonts/FoundersGroteskMono-Semibold.woff2';

import {
  APP,
  BREAKPOINTS,
  ROLE_KEYS,
  ROUTES,
  WORKSPACE
} from '../common/constants';
import RouterPrompt from '../components/RouterPrompt';
import useRedirectUser from '../hooks/useRedirectUser';
import { Select } from '../modules/videos/components/FormInputs';
import './App.css';
import ContentRoutes from './ContentRoutes';
import Sidebar from './components/sidebar/Sidebar';
import {
  GET_WORKSPACE,
  GET_WORKSPACES
} from './components/sidebar/graphql/Queries';

const { Content, Sider } = Layout;

const { Paragraph } = Typography;

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

function App() {
  const {
    state: { workspace, showPrompt, currentUser: { roles = [] } = null },
    dispatch,
    updateWorkspaceConfig
  } = useContext(AppContext);
  const ref = useRef();
  const history = useHistory();

  const location = useLocation();
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet
  );
  const [collapsed, setCollapsed] = useState(false);
  const [isActive, setActive] = useState(false);

  const [workspaceData, setWorkspaceData] = useState([]);
  const { redirectUser } = useRedirectUser();

  const [getWorkspace, { loading }] = useLazyQuery(GET_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const isWorkspaceActive = res?.workspace?.isActive;
      if (!isWorkspaceActive && roles?.[0] !== ROLE_KEYS.SUPER_ADMIN) {
        dispatch({
          type: 'SET_WORKSPACE',
          data: null
        });
      } else {
        dispatch({
          type: 'SET_WORKSPACE',
          data: {
            id: res?.workspace?.id,
            label: res?.workspace?.name,
            value: res?.workspace?.uuid
          }
        });
        const permissions = res?.workspace?.permissions || [];
        dispatch({
          type: 'SET_USER_PERMISSIONS',
          data: permissions
        });
        updateWorkspaceConfig(res?.workspace?.config);
      }
    },
    onError: () => {}
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const getId = JSON.parse(localStorage.getItem(WORKSPACE))?.id;
    if (getId)
      getWorkspace({
        variables: {
          where: {
            id: getId
          }
        }
      });
  }, []);

  const handleOverlay = () => {
    setActive(!isActive);
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const GetTriggerIcon = () => {
    if (collapsed) {
      return <MenuUnfoldOutlined />;
    }
    return <MenuFoldOutlined />;
  };

  const handleWorkspaceChange = (_, { config, ...newValue }) => {
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(APP);
    const permissions =
      find(workspaceData, (item) => item?.uuid === newValue?.value)
        ?.permissions || [];
    dispatch({
      type: 'SET_WORKSPACE',
      data: newValue
    });
    dispatch({
      type: 'SET_USER_PERMISSIONS',
      data: permissions
    });
    updateWorkspaceConfig(config);
    const getRoute = redirectUser(permissions);
    if (getRoute === location.pathname) {
      // eslint-disable-next-line no-undef
      window.location.reload();
    } else {
      history.push(getRoute);
    }
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Layout hasSider>
      <RouterPrompt openPrompt={showPrompt} />
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'd-hide'}
          onClick={handleOverlay}
        />
      )}
      <Sider
        width={197}
        trigger={GetTriggerIcon()}
        collapsible
        onCollapse={toggle}
        collapsed={isDesktop ? collapsed : false}
        className={isActive ? 'close' : null}
        breakpoint="lg"
      >
        <div className="right-border d-flex flex-vertical align-center justify-center">
          <Select
            ref={ref}
            className={`dropdown-padding sidebar-select mt-16 ${
              collapsed ? 'collapsed-sidebar' : ''
            }`}
            popupMatchSelectWidth={false}
            variablesSelector={variablesSelector}
            value={workspace}
            onChange={handleWorkspaceChange}
            placeholder="Workspace"
            query={GET_WORKSPACES}
            suffixIcon={
              collapsed ? null : (
                <DownOutlined className="pointer-events-none" />
              )
            }
            dropdownRender={(menu) => {
              return (
                <>
                  {[ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(
                    roles?.[0]
                  ) && (
                    <Button
                      className="select-add-btn"
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        history?.push(`${ROUTES?.WORKSPACES}/add`);
                      }}
                    >
                      Add Workspace
                    </Button>
                  )}
                  {menu}
                </>
              );
            }}
            optionRender={(option) => (
              <div className="d-flex justify-between align-center w-full">
                {option?.label}
                <div className="d-flex align-center">
                  {[ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(
                    roles?.[0]
                  ) && (
                    <Button
                      className="white-btn"
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        history?.push(
                          `${ROUTES?.WORKSPACES}/${option?.data?.id}/edit`
                        );
                      }}
                      icon={<EditOutlined />}
                    />
                  )}
                  <Paragraph
                    className="copy-icon"
                    copyable={{
                      text: option?.value,
                      tooltips: 'Copy Workspace UUID'
                    }}
                  />
                </div>
              </div>
            )}
            dataSelector={(data) =>
              data?.workspaces?.workspaces?.map(
                ({ id, uuid, name, config }) => ({
                  id,
                  label: name,
                  value: uuid,
                  config
                })
              ) || []
            }
            keys={{
              data: 'workspaces',
              records: 'workspaces',
              count: 'count'
            }}
            queryOptions={{
              skip: loading,
              onCompleted: ({ workspaces }) => {
                if (
                  isEmpty(workspaces?.workspaces) &&
                  roles?.[0] !== ROLE_KEYS?.SUPER_ADMIN
                ) {
                  history.push(ROUTES.LOGOUT);
                }
                const firstWorkspace = workspaces?.workspaces?.[0];
                const selectedWorkspace =
                  // eslint-disable-next-line no-undef
                  JSON?.parse(localStorage.getItem(WORKSPACE)) ||
                  (firstWorkspace
                    ? {
                        id: firstWorkspace?.id,
                        label: firstWorkspace?.name,
                        value: firstWorkspace?.uuid
                      }
                    : null);
                const permissions =
                  find(
                    workspaces?.workspaces,
                    (item) =>
                      item?.id === selectedWorkspace?.id ||
                      item?.uuid === selectedWorkspace?.value
                  )?.permissions || [];
                // eslint-disable-next-line no-undef
                if (!JSON?.parse(localStorage.getItem(WORKSPACE))) {
                  dispatch({
                    type: 'SET_USER_PERMISSIONS',
                    data: permissions
                  });
                }
                dispatch({ type: 'SET_WORKSPACE', data: selectedWorkspace });
                dispatch({ type: 'SET_LOADING', data: false });
                setWorkspaceData(uniqBy(workspaces?.workspaces, 'id'));
              },
              onError() {
                dispatch({
                  type: 'SET_WORKSPACE_APPS_LOADING',
                  data: false
                });
                dispatch({ type: 'SET_LOADING', data: false });
              }
            }}
          />
        </div>
        <Sidebar collapsed={collapsed} />
      </Sider>
      <Layout className="site-layout">
        <Content className="wrapper">
          <ContentRoutes />
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
