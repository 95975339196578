import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SOURCES = gql`
  query sourcesAdmin(
    $filter: SourcesFilter
    $where: SourcesWhereInput
    $sort: SourcesSort
  ) {
    sourcesAdmin(filter: $filter, where: $where, sort: $sort) {
      sources {
        id
        articlesCount
        createdAt
        description
        image {
          blurhash
          id
          url
        }
        isActive
        name
        podcastsCount
        shortCode
        videosCount
      }
      count
    }
  }
`;
