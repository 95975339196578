import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import AddEditContributor from './AddEditContributor';
import Contributor from './Contributor';
import './contributor.less';

const ContributorWrapper = () => (
  <Switch>
    <Route
      path={ROUTES?.CONTRIBUTOR}
      exact
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <Contributor {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.CONTRIBUTOR}/add`}
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <AddEditContributor {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.CONTRIBUTOR}/:id/edit`}
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <AddEditContributor {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.CONTRIBUTOR}/:id`}
      render={() => <Redirect to={ROUTES?.CONTRIBUTOR} />}
    />
  </Switch>
);

export default ContributorWrapper;
