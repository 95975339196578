import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { authClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import './auth.less';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgetPassword = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    client: authClient
  });

  const onFinish = async (values) => {
    try {
      const payload = {
        email: values?.email?.trim().toLowerCase()
      };
      const { error } = await forgotPassword({
        variables: {
          data: payload
        }
      });
      if (!error) form?.resetFields();
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="login-section">
      <div className="login-img">
        <h1>Media Command Center</h1>
        <p>Administration</p>
      </div>
      <div className="login-wrap d-flex align-center justify-center">
        <Card className="full-width">
          <div className="text-left">
            <h2 className="text-left mt-8">Forgot Your Password ?</h2>
            <p className="text-left">
              Don't worry. Recovering the password is easy. Just tell us the
              email.
            </p>
          </div>
          <Spin spinning={false}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                email: ''
              }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button
                  type="primary"
                  loading={loading}
                  className="full-width"
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  onClick={() => {
                    history?.push(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  );
};

export default ForgetPassword;
