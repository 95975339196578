import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Flex, Radio, Space, Spin, Tooltip, Typography } from 'antd';
import { ArrowClockwise, Eye } from 'phosphor-react';
import React, { forwardRef, memo, useMemo, useState } from 'react';
import ModalComponent from '../../../../../components/ModalComponent';
import { usePagePreview } from '../../../context/PreviewPageProvider';

const SIZES = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP'
};

const SIZES_OPTIONS = [
  {
    label: 'Mobile',
    value: SIZES.MOBILE,
    icon: <MobileOutlined />,
    className: 'mobile'
  },
  {
    label: 'Desktop',
    value: SIZES.DESKTOP,
    icon: <DesktopOutlined />,
    className: 'desktop'
  }
];

export const PreviewFrame = memo(
  forwardRef(({ onLoad, url, loading }, ref) => {
    if (!url && !loading) {
      return <p>Web page not found!</p>;
    }

    return (
      <iframe
        ref={ref}
        className="preview-frame"
        onLoad={onLoad}
        src={url}
        title="Preview"
      />
    );
  })
);

export const Preview = () => {
  const { url, iframeRef, onLoad, loading } = usePagePreview();

  return (
    <PreviewFrame ref={iframeRef} loading={loading} onLoad={onLoad} url={url} />
  );
};

export const PreviewModalContent = () => {
  const { url, onLoad, loading } = usePagePreview();

  const [size, setSize] = useState(SIZES.DESKTOP);

  const sizeObject = useMemo(
    () => SIZES_OPTIONS.find(({ value }) => value === size),
    [size]
  );

  return (
    <Flex className="h-full" vertical gap={24}>
      <Flex align="center" justify="center">
        <Radio.Group
          className="custom-radio-group"
          size="small"
          onChange={(e) => setSize(e?.target?.value)}
          value={size}
        >
          {SIZES_OPTIONS.map(({ value, icon }) => (
            <Radio.Button value={value} key={value}>
              {icon}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Flex>

      <div
        className={`preview-frame-wrapper ${
          sizeObject ? sizeObject?.className : ''
        }`}
      >
        {loading && (
          <div className="loading">
            <Spin />
          </div>
        )}
        <PreviewFrame loading={loading} onLoad={onLoad} url={url} />
      </div>
    </Flex>
  );
};

const PreviewModal = ({ open, onCancel }) => {
  return (
    <ModalComponent
      className="page-preview-modal"
      destroyOnClose
      open={open}
      onCancel={onCancel}
      footer={null}
      width="100vw"
    >
      <PreviewModalContent />
    </ModalComponent>
  );
};

const PreviewWrapper = () => {
  const { refetch, loading } = usePagePreview();
  const [showPreview, setShowPreview] = useState(false);

  const handleShowPreview = () => setShowPreview(true);
  const handlePreviewClose = () => setShowPreview(false);
  return (
    <>
      <PreviewModal open={showPreview} onCancel={handlePreviewClose} />
      <Space className="d-flex mb-8 justify-between">
        <Typography.Text>Preview</Typography.Text>

        <Space align="center" size={0}>
          <Tooltip title="Preview" placement="topLeft" arrow={false}>
            <Button
              type="text"
              icon={<Eye size={20} />}
              onClick={handleShowPreview}
            />
          </Tooltip>
          <Tooltip title="Refresh preview" placement="topLeft" arrow={false}>
            <Button
              type="text"
              icon={<ArrowClockwise size={20} />}
              onClick={refetch}
              disabled={loading}
              loading={loading}
            />
          </Tooltip>
        </Space>
      </Space>
      <div className="preview-frame-wrapper">
        {loading && (
          <div className="loading">
            <Spin />
          </div>
        )}
        <Preview />
      </div>
    </>
  );
};

export default PreviewWrapper;
