import React from 'react';
import { MODULES } from '../../../common/constants';
import PageHeader from '../../../components/PageHeader';
import CommonDocumentForm from './CommonDocumentForm';

const AddEditDocument = () => {
  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <CommonDocumentForm />
        </div>
      </div>
    </>
  );
};

export default AddEditDocument;
