import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LIST_TYPES,
  ROLES_SORT_FIELD,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { GET_WORKSPACE_ROLES } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.workspaceRoles?.workspaceRoles ?? [],
  count: data?.workspaceRoles?.count ?? 0
});

const variablesSelector = ({ offset, sortField, sortOrder, ...rest }) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const Action = ({ id, data }) => {
  const history = useHistory();
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const handleEdit = () => {
    history?.push(`${ROUTES?.ROLES}/${id}/edit`);
  };

  return (
    <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Role`}>
      <Button
        type="text"
        className="text-btn"
        onClick={handleEdit}
        disabled={data?.isDefault}
      >
        {isViewOnly ? <EyeFilled /> : <EditOutlined />}
      </Button>
    </Tooltip>
  );
};

const columns = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: '20%'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '80%',
      render: (value, record) => <Action id={value} data={record} />
    }
  ];
};

const Roles = ({ history }) => {
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: ROLES_SORT_FIELD,
      field: ROLES_SORT_FIELD?.[0]?.value
    }
  });

  const filters = useMemo(() => ({ ...filterProps?.filters }), [
    filterProps?.filters
  ]);

  const handleShowModal = () => {
    history?.push(`${ROUTES?.ROLES}/add`);
  };

  return (
    <div>
      <PageHeader
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false
        }}
        SearchProps={{ placeholder: 'Search Roles' }}
      />
      <PageList
        filters={filters}
        listMode={LIST_TYPES?.TABLE}
        query={GET_WORKSPACE_ROLES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props, isAddEditAllowed })?.filter(
              (item) => item !== false
            )
        }}
        limit={20}
      />
    </div>
  );
};

export default Roles;
