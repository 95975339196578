import {
  CommentOutlined,
  EyeOutlined,
  FlagOutlined,
  LikeOutlined
} from '@ant-design/icons';
import { Avatar, Flex } from 'antd';
import moment from 'moment';

import React, { useMemo, useState } from 'react';
import {
  LIST_TYPES,
  MODULES,
  POSTS_SORT_FIELD
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import { GET_COMMUNITIES } from '../../communities/graphql/Queries';
import { Select } from '../../videos/components/FormInputs';
import { GET_POSTS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.postsAdmin?.posts ?? [],
  count: data?.postsAdmin?.count ?? 0
});

const variablesSelector = ({
  offset,
  sortField,
  sortOrder,
  search: _search,
  ...rest
}) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

function HandsPraying() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <path
        d="M141.78,16h0A13.77,13.77,0,0,1,155,25.78L192,148l20.27,20.27-45,43-29.94-29.94A32,32,0,0,1,128,158.75v-129A13.78,13.78,0,0,1,141.78,16Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M167.31,211.31l18.35,18.35a8,8,0,0,0,11.31,0L229.66,197a8,8,0,0,0,0-11.31l-18.35-18.35"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M118.63,181.37,88.69,211.31l-44-44L64,148,101,25.78A13.77,13.77,0,0,1,114.22,16h0A13.78,13.78,0,0,1,128,29.78v129A32,32,0,0,1,118.63,181.37Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M44.69,167.31,26.34,185.66a8,8,0,0,0,0,11.31L59,229.66a8,8,0,0,0,11.31,0l18.35-18.35"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
}

function Count({ icon, value }) {
  return (
    <Flex className="count-wrapper" align="center" gap={4}>
      {icon} {value ?? 0}
    </Flex>
  );
}

function PostCard({
  createdBy,
  createdAt,
  text,
  flagCount,
  viewCount,
  prayerCount,
  likeCount,
  commentCount
}) {
  return (
    <div className="post-card">
      <Flex className="mb-8" gap={8} align="center">
        <Avatar src={createdBy?.profileImage}>
          {[createdBy?.firstName, createdBy?.lastName]
            .filter(Boolean)
            .map((name) => name[0]?.toUpperCase())
            .join('')}
        </Avatar>
        <Flex vertical gap={2}>
          <p className="heading-h5">
            {createdBy?.firstName} {createdBy?.lastName}
          </p>
          <p className="created-at">{moment(createdAt).fromNow()}</p>
        </Flex>
      </Flex>
      <p className="mb-8">{text}</p>
      <Flex align="center" gap={8} className="stats-wrapper">
        <Count icon={<EyeOutlined />} value={viewCount} />
        <Count icon={<CommentOutlined />} value={commentCount} />
        <Count icon={<LikeOutlined />} value={likeCount} />
        <Count icon={<FlagOutlined />} value={flagCount} />
        <Count icon={<HandsPraying />} value={prayerCount} />
      </Flex>
    </div>
  );
}

const Posts = () => {
  const [community, setCommunity] = useState(null);
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: POSTS_SORT_FIELD,
      field: POSTS_SORT_FIELD[0].value
    }
  });

  const handleCommunityChange = (_, value) => {
    setCommunity(value);
  };

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      communityId: community?.value
    }),
    [filterProps.filters, community]
  );

  return (
    <>
      <PageHeader menu={MODULES?.POSTS} />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false,
          search: false
        }}
        SearchProps={{ placeholder: 'Search Posts' }}
      >
        <Select
          allowClear
          value={community}
          onChange={handleCommunityChange}
          placeholder="Select Community"
          query={GET_COMMUNITIES}
          variablesSelector={(filter) => ({ filter })}
          dataSelector={(data) =>
            data?.communitiesAdmin?.communities?.map(({ id, title }) => ({
              label: title,
              value: id
            })) ?? []
          }
          keys={{
            data: 'communitiesAdmin',
            records: 'communities',
            count: 'count'
          }}
        />
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.GRID}
        query={GET_POSTS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: PostCard,
          breakPoints: { md: 24, lg: 12 }
        }}
      />
    </>
  );
};

export default Posts;
