import { Avatar, Badge, Button, Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  BookOpen,
  BookmarkSimple,
  CaretRight,
  ChatCircleText,
  ShareNetwork
} from 'phosphor-react';
import React from 'react';
import TagGrid from '../tagModules/TagGrid';

function FeatureArticleCard({
  topicName,
  title,
  description,
  tagData,
  src,
  className,
  prayerCount = 0,
  commentCount = 0,
  showCTA,
  publishedAt,
  author,
  html
}) {
  const { Paragraph } = Typography;
  return (
    <section className="featured-carousel responsive-view">
      <div
        className={`featuredvideocard article-card center-false ${className}`}
      >
        <div className="fvc-image">
          <img src={src} alt="Featured Article" />
        </div>
        <div className="fvc-details">
          <div className="fvc-description article-description">
            {topicName && (
              <a prefetch={false} href="/">
                <Badge className="badge-md bg-red-2 text-white-90 bg-red-2 text-n-900">
                  {topicName}
                </Badge>
              </a>
            )}

            <div className="heading">{title}</div>
            <Paragraph className="description text-white-90">
              {description}
            </Paragraph>
            {publishedAt && (
              <div className="text-red mb-12">
                {moment(publishedAt).fromNow()}
              </div>
            )}
            {!isEmpty(author) && (
              <div className="video-avatar-group mb-16">
                <a
                  href="/"
                  className="item d-flex align-center gap-8 flex-shrink-0"
                >
                  <Avatar
                    className="overflow-hidden"
                    size="small"
                    {...(author?.image?.url && {
                      src: author?.image?.url
                    })}
                    alt={author?.name?.charAt(0)}
                  />
                  <div>
                    <div className="text-lg text-white-90">{author?.name}</div>
                    <div className="text-md text-white-70">
                      {author?.subTitle && (
                        <div className="text-md text-white-70">
                          {author?.subTitle}
                        </div>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            )}
            {tagData?.length > 0 && (
              <TagGrid
                className="text-md tag-xs bg-tags b-0 text-n-0 mr-4"
                data={tagData}
                icon={<CaretRight className="ml-2" size={16} />}
              />
            )}

            <div className="comment-count">
              {showCTA?.comment && (
                <div className="text-white-80">{commentCount} Comments</div>
              )}
              {showCTA?.prayer && (
                <div className="text-white-80">{prayerCount} Prayers</div>
              )}
            </div>
          </div>
          <div className="slider-button">
            {showCTA?.prayer && (
              <Tooltip placement="top" title="Pray">
                <Button
                  className="text-black-900 b-0 cs-button bg-ivory gap-4 hbg-n-200 hc-black-70 d-flex flex-column"
                  size="small"
                  block
                >
                  <BookOpen size={24} />
                  Pray
                </Button>
              </Tooltip>
            )}
            {showCTA?.comment && (
              <Tooltip placement="top" title="Comment">
                <Button
                  className="text-black-900 b-0 cs-button bg-ivory gap-4 hbg-n-200 hc-black-70"
                  size="small"
                  block
                >
                  <ChatCircleText size={24} />
                  Comment
                </Button>
              </Tooltip>
            )}
            {showCTA?.share && (
              <Tooltip placement="top" title="Share">
                <Button
                  className=" text-black-900 b-0 cs-button bg-ivory gap-4 hbg-n-200 hc-black-70"
                  size="small"
                  block
                >
                  <ShareNetwork size={24} />
                  Share
                </Button>
              </Tooltip>
            )}
            {showCTA?.save && (
              <Tooltip placement="top" title="Save">
                <Button
                  className="text-black-900 b-0 cs-button bg-ivory gap-4 hbg-n-200 hc-black-70"
                  size="small"
                  block
                >
                  <BookmarkSimple weight="bold" size={24} />
                  Save
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {html && (
        <div
          className="cms-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: html
          }}
        />
      )}
    </section>
  );
}
export default FeatureArticleCard;
