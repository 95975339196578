import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import AddEditCollection from './AddEditCollection';
import Collections from './Collections';

const CollectionsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.COLLECTIONS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Collections {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.COLLECTIONS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditCollection {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.COLLECTIONS}/:collectionId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditCollection {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.COLLECTIONS}/:collectionId`}
        render={() => <Redirect to={ROUTES?.COLLECTIONS} />}
      />
    </Switch>
  );
};

export default CollectionsWrapper;
