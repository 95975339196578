import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import { omit } from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import { GET_WORKSPACES_APPS } from '../../../../../app/components/sidebar/graphql/Queries';
import {
  LOGO_REDIRECTION_TYPE,
  MAX_LENGTHS,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  REDIRECTION_TYPE,
  REGEX,
  STATIC_PAGES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { SlugInput } from '../../../../labels/topics/components/FormInputs';
import { GET_SLUGS } from '../../../../menus/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { useEditPage } from '../../../context/EditPageProvider';
import { UPDATE_PAGE } from '../../../graphql/Mutations';
import { Permissions, Switch } from './FormInputs';

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const Settings = () => {
  const {
    setSelectionType,
    settings,
    fetchingPageDetails,
    pageType,
    refetchPageDetails
  } = useEditPage();
  const [form] = Form.useForm();

  const redirectionPageType = Form.useWatch(
    ['logoRedirection', 'pageType'],
    form
  );
  const internalPageType = Form.useWatch(
    ['logoRedirection', 'internalPageType'],
    form
  );
  const logoRedirectionEnabled = Form.useWatch(
    ['logoRedirection', 'enabled'],
    form
  );

  useEffect(() => {
    form.setFieldsValue({
      ...settings,
      permissions: settings?.permissions?.map((value) => ({
        label: value,
        value
      })),
      logoRedirection: {
        ...settings?.logoRedirection,
        ...(settings?.logoRedirection?.pageType ===
          REDIRECTION_TYPE.INTERNAL && {
          ...(settings?.logoRedirection?.internalPageType === PAGE_TYPES.STATIC
            ? { slug: settings?.logoRedirection?.url }
            : {
                slug: settings?.logoRedirection?.url
                  ? {
                      label: `/${settings?.logoRedirection?.url}`,
                      value: settings?.logoRedirection?.url
                    }
                  : null
              })
        })
      }
    });
  }, [settings, form]);

  const { id } = useParams();

  const [updatePage, { loading }] = useMutation(UPDATE_PAGE);

  const handleSubmit = ({ slug, apps, ...values }) => {
    const appIds = apps?.map(({ value }) => value);
    const payload = {
      ...values,
      permissions: values?.permissions?.map(({ value }) => value),
      ...(settings?.allowDelete && {
        slug: slug.substring(1)
      }),
      appIds,
      logoRedirection: {
        ...omit(values?.logoRedirection, ['slug']),
        ...(values?.logoRedirection?.pageType === REDIRECTION_TYPE.INTERNAL && {
          url: values?.logoRedirection?.url?.startsWith('/')
            ? `${
                !values?.logoRedirection?.url?.substring(1) ? '/' : ''
              }${values?.logoRedirection?.url?.substring(1)}`
            : values?.logoRedirection?.url
        })
      }
    };
    updatePage({
      variables: {
        data: payload,
        where: { id }
      }
    })
      .then(() => {
        setSelectionType('');
        refetchPageDetails();
      })
      .catch();
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const handleCancel = () => {
    setSelectionType('');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={settings}
      disabled={fetchingPageDetails}
    >
      <Form.Item
        label="Title"
        name="title"
        required
        rules={[
          formValidatorRules?.required('Please enter title!'),
          formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
        ]}
      >
        <Input placeholder="Enter title" onChange={handleTitleChange} />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Please enter slug!'
          },
          formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
        ]}
      >
        <SlugInput
          readOnly={!settings?.allowDelete}
          disabled={!settings?.allowDelete}
        />
      </Form.Item>
      {pageType === PAGE_TYPES.CUSTOM && (
        <Form.Item label="Apps" name="apps">
          <Select
            multiple
            placeholder="Select apps"
            query={GET_WORKSPACES_APPS}
            variablesSelector={variablesSelector}
            dataSelector={(data) => {
              return (
                data?.workspaceApps?.workspaceApps?.map(
                  ({ id: key, name }) => ({
                    label: name,
                    value: key
                  })
                ) ?? []
              );
            }}
            keys={{
              data: 'workspaceApps',
              records: 'workspaceApps',
              count: 'count'
            }}
          />
        </Form.Item>
      )}
      <Form.Item
        label="Description"
        name="description"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>

      <fieldset className="mb-16">
        <legend className="role-legend text-label">Logo Redirection</legend>
        <Form.Item
          name={['logoRedirection', 'enabled']}
          valuePropName="checked"
        >
          <Switch label="Active" />
        </Form.Item>
        <Form.Item
          label="Type"
          name={['logoRedirection', 'pageType']}
          rules={[
            {
              required: logoRedirectionEnabled,
              message: 'Please select page type!'
            }
          ]}
        >
          <AntdSelect
            disabled={!logoRedirectionEnabled || false}
            options={LOGO_REDIRECTION_TYPE}
            placeholder="Select page type"
            onChange={() => {
              form.resetFields([
                ['logoRedirection', 'internalPageType'],
                ['logoRedirection', 'slug'],
                ['logoRedirection', 'url']
              ]);
            }}
          />
        </Form.Item>
        {redirectionPageType === REDIRECTION_TYPE.INTERNAL && (
          <>
            <Form.Item
              label="Page Type"
              name={['logoRedirection', 'internalPageType']}
              rules={[
                {
                  required:
                    logoRedirectionEnabled &&
                    redirectionPageType === REDIRECTION_TYPE.INTERNAL,
                  message: 'Please select page type!'
                }
              ]}
            >
              <AntdSelect
                disabled={!logoRedirectionEnabled || false}
                options={PAGE_TYPES_OPTIONS}
                placeholder="Select page type"
                onChange={() => {
                  form.resetFields([
                    ['logoRedirection', 'slug'],
                    ['logoRedirection', 'url']
                  ]);
                }}
              />
            </Form.Item>
            {internalPageType && (
              <>
                {internalPageType === PAGE_TYPES.STATIC ? (
                  <Form.Item
                    label="Page"
                    name={['logoRedirection', 'slug']}
                    rules={[
                      {
                        required:
                          logoRedirectionEnabled &&
                          redirectionPageType === REDIRECTION_TYPE.INTERNAL,
                        message: 'Please select page!'
                      }
                    ]}
                  >
                    <AntdSelect
                      disabled={!logoRedirectionEnabled || false}
                      options={STATIC_PAGES}
                      onChange={(value) => {
                        form.setFieldValue(
                          ['logoRedirection', 'url'],
                          `/${value}`
                        );
                        form.validateFields(['logoRedirection', 'url']);
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Page"
                    name={['logoRedirection', 'slug']}
                    rules={[
                      {
                        required:
                          logoRedirectionEnabled &&
                          redirectionPageType === REDIRECTION_TYPE.INTERNAL,
                        message: 'Please select page!'
                      }
                    ]}
                  >
                    <Select
                      disabled={!logoRedirectionEnabled || false}
                      placeholder="Select"
                      query={GET_SLUGS}
                      variablesSelector={(filters) => ({
                        filter: {
                          ...filters,
                          pageType: internalPageType
                        }
                      })}
                      dataSelector={(data) => {
                        return (
                          data?.slugs?.slugs?.map(({ slug }) => ({
                            label: `/${slug}`,
                            value: slug
                          })) ?? []
                        );
                      }}
                      keys={{
                        data: 'slugs',
                        records: 'slugs',
                        count: 'count'
                      }}
                      onChange={({ value }) => {
                        form.setFieldValue(
                          ['logoRedirection', 'url'],
                          `/${value}`
                        );
                        form.validateFields(['logoRedirection', 'url']);
                      }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}
        <Form.Item
          label="URL"
          name={['logoRedirection', 'url']}
          rules={[
            {
              required: logoRedirectionEnabled,
              message: 'Please enter URL!'
            },
            {
              async validator(_, value) {
                if (
                  redirectionPageType === REDIRECTION_TYPE.EXTERNAL &&
                  value &&
                  !REGEX?.WEB_URL?.test(value)
                ) {
                  return Promise?.reject(new Error('Should be a valid URL'));
                }
                return Promise?.resolve();
              }
            }
          ]}
        >
          <Input
            placeholder="Enter url"
            readOnly={redirectionPageType === REDIRECTION_TYPE.INTERNAL}
            disabled={
              redirectionPageType === REDIRECTION_TYPE.INTERNAL ||
              !logoRedirectionEnabled ||
              false
            }
          />
        </Form.Item>
      </fieldset>

      <Form.Item
        name="metaHeader"
        label="Meta Header"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea rows={5} placeholder="Enter meta header" />
      </Form.Item>

      <Form.Item
        name="metaFooter"
        label="Meta Footer"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea rows={5} placeholder="Enter meta footer" />
      </Form.Item>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              disabled={fetchingPageDetails || loading}
              loading={loading}
              htmlType="submit"
              className="text-btn mr-8"
              type="text"
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="text"
              disabled={fetchingPageDetails || loading}
              className="text-btn2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default Settings;
