import { gql } from '@apollo/client';

export const CREATE_UI_LABEL = gql`
  mutation createUILabel($data: CreateUILabelInput!) {
    createUILabel(data: $data) {
      message
    }
  }
`;

export const UPDATE_UI_LABEL = gql`
  mutation updateUILabel($id: ID!, $data: UpdateUILabelInput!) {
    updateUILabel(where: { id: $id }, data: $data) {
      message
    }
  }
`;

export const DELETE_UI_LABEL = gql`
  mutation deleteUILabel($id: ID!) {
    deleteUILabel(where: { id: $id }) {
      message
    }
  }
`;
