import { gql } from '@apollo/client';

export const COMMON_ASSET_FIELDS = gql`
  fragment CommonAssetFields on Asset {
    id
    url
    blurhash
  }
`;

export const GET_ARTICLES_MODULE = gql`
  query ArticlesAdmin($filter: ArticlesFilter, $sort: ArticlesSort) {
    articlesAdmin(filter: $filter, sort: $sort) {
      count
      articles {
        id
        title
        slug
        description
        createdAt
        imageThumbnail {
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  ${COMMON_ASSET_FIELDS}
  query ArticleAdmin($where: ArticleWhereUniqueInput!) {
    articleAdmin(where: $where) {
      title
      description
      slug
      tags {
        id
        name
      }
      topics {
        id
        name
      }
      sources {
        id
        name
        image {
          url
          id
        }
      }
      previousPageRedirection {
        url
      }
      status
      contentRating
      metaHeader
      metaFooter
      authors {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      image {
        ...CommonAssetFields
      }
      imageThumbnail {
        ...CommonAssetFields
      }
      permissions
      code
      html
    }
  }
`;

export const GET_SOURCES = gql`
  query sources(
    $filter: SourcesFilter
    $sort: SourcesSort
    $where: SourcesWhereInput
  ) {
    sourcesAdmin(filter: $filter, sort: $sort, where: $where) {
      count
      sources {
        id
        name
        description
        createdAt
        image {
          url
          blurhash
        }
      }
    }
  }
`;
