import { CheckOutlined } from '@ant-design/icons';
import {
  Select as AntdSelect,
  Button,
  Card,
  Col,
  Row,
  Space,
  theme
} from 'antd';
import { map } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import audioPlaceholder from '../../../../assets/images/audioPlaceholder.jpg';
import videoPlaceholder from '../../../../assets/images/imagePlaceholder.png';
import {
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS
} from '../../../../common/constants';
import { getImageUrl } from '../../../../common/utils';
import Image from '../../../../components/Image';
import SelectableModal from '../../../../components/SelectableModal';
import { GET_ARTICLES_MODULE } from '../../../articles/graphql/Queries';
import { GET_COMMUNITIES } from '../../../communities/graphql/Queries';
import { GET_PODCASTS_MODULE } from '../../../podcast/graphql/Queries';
import { Select } from '../../../videos/components/FormInputs';
import Preview from '../../../videos/components/Preview';
import {
  GET_TOPICS,
  GET_VIDEOS_MODULES
} from '../../../videos/graphql/Queries';

const VideoCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

const CommunityCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageUrl, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectVideos = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [statusValue, setStatusValue] = useState([STATUS_TYPES.PUBLISHED]);
  const [topicIdsValue, setTopicIdsValue] = useState([]);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  const handleChangeStatus = useCallback((status) => {
    setStatusValue(status);
  }, []);

  const handleChangeTopics = useCallback((topicValue) => {
    setTopicIdsValue(topicValue);
  }, []);

  const filters = useMemo(
    () => ({
      status: statusValue,
      topicIds: topicIdsValue
    }),
    [statusValue, topicIdsValue]
  );

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Videos' : 'Select Video'}
        open={isOpen}
        onClose={closeModal}
        query={GET_VIDEOS_MODULES}
        variablesSelector={(offset, limit, search, ...otherFilters) => {
          return {
            filter: {
              search,
              skip: offset,
              limit,
              status: otherFilters?.[0]?.status || [],
              topicIds:
                map(otherFilters?.[0]?.topicIds, (item) => item?.value) || []
            }
          };
        }}
        dataSelector={(data) => data?.videosAdmin?.videos ?? []}
        keys={{
          data: 'videosAdmin',
          records: 'videos',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <VideoCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
        filters={filters}
        renderFilters={() => (
          <div className="full-width">
            <Row align="middle" justify="end" gutter={8} className="full-width">
              <Col span={10} className="d-flex align-center">
                <span className="text-label mr-4">Status</span>
                <AntdSelect
                  mode="multiple"
                  maxTagCount="responsive"
                  className="full-width"
                  value={statusValue}
                  onChange={handleChangeStatus}
                  options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                    ({ name, value: status }) => ({
                      label: name,
                      value: status
                    })
                  )}
                  placeholder="Select status"
                />
              </Col>
              <Col span={10} className="d-flex align-center">
                <span className="text-label mr-4">Topics</span>
                <Select
                  className="full-width"
                  value={topicIdsValue}
                  onChange={handleChangeTopics}
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Select topic"
                  query={GET_TOPICS}
                  variablesSelector={(filter) => ({ filter })}
                  dataSelector={(data) =>
                    data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                      label: name,
                      value: id
                    })) ?? 0
                  }
                  keys={{
                    data: 'topicsAdmin',
                    records: 'topics',
                    count: 'count'
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Videos' : 'Video'}
        </Button>
      </Space>
    </>
  );
};

export const SelectCommunities = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageUrl ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageUrl ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Communities' : 'Select Community'}
        open={isOpen}
        onClose={closeModal}
        query={GET_COMMUNITIES}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.communitiesAdmin?.communities ?? []}
        keys={{
          data: 'communitiesAdmin',
          records: 'communities',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <CommunityCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Communities' : 'Community'}
        </Button>
      </Space>
    </>
  );
};

const PodcastCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? audioPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

const ArticleCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectArticle = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [statusValue, setStatusValue] = useState([STATUS_TYPES.PUBLISHED]);
  const [topicIdsValue, setTopicIdsValue] = useState([]);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  const handleChangeStatus = useCallback((status) => {
    setStatusValue(status);
  }, []);

  const handleChangeTopics = useCallback((topicValue) => {
    setTopicIdsValue(topicValue);
  }, []);

  const filters = useMemo(
    () => ({
      status: statusValue,
      topicIds: topicIdsValue
    }),
    [statusValue, topicIdsValue]
  );

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Articles' : 'Select Article'}
        open={isOpen}
        onClose={closeModal}
        query={GET_ARTICLES_MODULE}
        variablesSelector={(offset, limit, search, ...otherFilters) => {
          return {
            filter: {
              search,
              skip: offset,
              limit,
              status: otherFilters?.[0]?.status || [],
              topicIds:
                map(otherFilters?.[0]?.topicIds, (item) => item?.value) || []
            }
          };
        }}
        dataSelector={(data) => data?.articlesAdmin?.articles ?? []}
        keys={{
          data: 'articlesAdmin',
          records: 'articles',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id)?.includes(item?.id)
            : value?.id === item?.id;
          return (
            <ArticleCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
        filters={filters}
        renderFilters={() => (
          <div className="full-width">
            <Row align="middle" justify="end" gutter={8} className="full-width">
              <Col span={10} className="d-flex align-center">
                <span className="text-label mr-4">Status</span>
                <AntdSelect
                  mode="multiple"
                  maxTagCount="responsive"
                  className="full-width"
                  value={statusValue}
                  onChange={handleChangeStatus}
                  options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                    ({ name, value: status }) => ({
                      label: name,
                      value: status
                    })
                  )}
                  placeholder="Select status"
                />
              </Col>
              <Col span={10} className="d-flex align-center">
                <span className="text-label mr-4">Topics</span>
                <Select
                  className="full-width"
                  value={topicIdsValue}
                  onChange={handleChangeTopics}
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Select topic"
                  query={GET_TOPICS}
                  variablesSelector={(filter) => ({ filter })}
                  dataSelector={(data) =>
                    data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                      label: name,
                      value: id
                    })) ?? 0
                  }
                  keys={{
                    data: 'topicsAdmin',
                    records: 'topics',
                    count: 'count'
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value?.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Articles' : 'Article'}
        </Button>
      </Space>
    </>
  );
};

export const SelectPodcasts = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Podcasts' : 'Select Podcast'}
        open={isOpen}
        onClose={closeModal}
        query={GET_PODCASTS_MODULE}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.podcastsAdmin?.podcasts ?? []}
        keys={{
          data: 'podcastsAdmin',
          records: 'podcasts',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <PodcastCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || audioPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || audioPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.id ? 'Change' : 'Select'} {multiple ? 'Podcasts' : 'Podcast'}
        </Button>
      </Space>
    </>
  );
};
