import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import SelectAsset from '../../../../assets/components/SelectAsset';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { initialAssetValues } from './CTAForm';
import { ModuleFields, Permissions, ShowFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    indicators: true,
    authorName: true,
    authorSubTitle: true
  },
  config: {
    quotes: []
  }
};

const SETTINGS = [
  {
    name: 'authorName',
    label: 'Speaker Name',
    allowedTypes: [MODULE_TYPES.QUOTE]
  },
  {
    name: 'authorSubTitle',
    label: 'Speaker Subtitle',
    allowedTypes: [MODULE_TYPES.QUOTE]
  },
  {
    name: 'indicators',
    label: 'Indicators',
    allowedTypes: [MODULE_TYPES.QUOTE]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.QUOTE]: 'quoteModule'
};

const QuoteInout = ({ name, remove, ...restField }) => {
  return (
    <>
      <Form.Item
        name={[name, 'text']}
        label="Text"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)]}
        {...restField}
      >
        <Input placeholder="Enter text" />
      </Form.Item>
      <Form.Item
        name={[name, 'author', 'name']}
        label="Speaker Name"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.NAME)]}
        {...restField}
      >
        <Input placeholder="Enter speaker name" />
      </Form.Item>
      <Form.Item
        name={[name, 'author', 'subTitle']}
        label="Sub Title"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)]}
        {...restField}
      >
        <Input placeholder="Enter subtitle" />
      </Form.Item>
      <Form.Item label="Speaker Image" name={[name, 'author', 'asset']}>
        <SelectAsset
          modalTitle="Select Speaker Image"
          categoryKey={ASSET_CATEGORY.IMAGE}
          btnText="Speaker Image"
          dataSelector={({ id, url }) => ({
            id,
            url
          })}
        />
      </Form.Item>
      <Button
        className="text-btn mb-12"
        type="text"
        size="small"
        onClick={() => remove(name)}
      >
        Remove Quote
      </Button>
    </>
  );
};

const QuoteForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          quotes:
            defaultValues?.moduleData?.config?.quotes?.map((quote) => ({
              text: quote?.text ?? '',
              author: {
                name: quote?.author?.name,
                subTitle: quote?.author?.subTitle,
                asset: quote?.author?.asset ?? {
                  ...initialAssetValues
                }
              }
            })) ?? []
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const { quotes } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          quotes: quotes.map(({ text, author: { name, subTitle, asset } }) => ({
            text,
            author: {
              name,
              subTitle,
              assetId: asset?.id ?? null
            }
          }))
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>Quote Configs</Typography.Text>
          <div>
            <Form.List name={['config', 'quotes']}>
              {(fields, { add, remove }) => (
                <>
                  {fields?.map((field) => (
                    <QuoteInout remove={remove} key={field?.key} {...field} />
                  ))}
                  <Form.Item>
                    <Button
                      className="text-btn"
                      type="text"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Quote
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </Space>
      </Space>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default QuoteForm;
