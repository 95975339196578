import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import AddEditTopic from './AddEditTopic';

import Topics from './Topics';

const TopicsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.TOPICS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Topics {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.TOPICS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditTopic {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.TOPICS}/:topicId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditTopic {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.TOPICS}/:topicId`}
        render={() => <Redirect to={ROUTES?.TOPICS} />}
      />
    </Switch>
  );
};

export default TopicsWrapper;
