import React from 'react';
import { PreviewModalContent } from '.';
import ModalComponent from '../../../../../components/ModalComponent';
import PreviewPageProvider from '../../../context/PreviewPageProvider';

const PreviewModal = ({ pageId, open = false, handleCancel }) => {
  return (
    <ModalComponent
      className="page-preview-modal"
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      footer={null}
      width="100vw"
    >
      <PreviewPageProvider pageId={pageId}>
        <PreviewModalContent />
      </PreviewPageProvider>
    </ModalComponent>
  );
};

export default PreviewModal;
