import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import NewsLetters from './NewsLetters';

const NewsLetterWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.LEAD_GENS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <NewsLetters {...props} />
          </AccessControl>
        )}
      />
    </Switch>
  );
};

export default NewsLetterWrapper;
