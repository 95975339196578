import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEditUser from './AddEditAdminUser';
import AdminUsers from './AdminUsers';

const AdminUsersWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.ADMIN_USERS} component={AdminUsers} />
      <Route
        exact
        path={`${ROUTES?.ADMIN_USERS}/add`}
        component={AddEditUser}
      />
      <Route
        exact
        path={`${ROUTES?.ADMIN_USERS}/:adminUserId/edit`}
        component={AddEditUser}
      />
      <Route
        exact
        path={`${ROUTES?.ADMIN_USERS}/:adminUserId`}
        render={() => <Redirect to={ROUTES?.ADMIN_USERS} />}
      />
    </Switch>
  );
};

export default AdminUsersWrapper;
